import React, { useState } from "react";
import { isPDX } from "static-config";
import { GetReportErrorCodes } from "api/insight-reports";

import {
  useInsightReport,
  isShowingInsightsReport
} from "util/hooks/useInsightReport";
import { PopoverModalContextProvider } from "util/hooks/usePopoverModal/provider";
import {
  InsightReportActions,
  InsightReportStatus
} from "util/hooks/useInsightReport/types";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import { InsightsFeatureProvider } from "util/hooks/useInsightsFeatures";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import useViewerMode from "util/hooks/useViewerMode";
import SourcingExplainer from "components/molecules/SourcingExplainerModal";
import StatusNotification from "components/molecules/StatusNotification";

import { Section } from "pages/report/Section";

import MainMenuControl from "./MainMenuControl";
import ReportContent from "./ReportContent";
import LoadingReport from "./LoadingReport";
import ErrorGenerating from "./ErrorGenerating";
import WelcomeDisclaimer from "./WelcomeDisclaimer";
import DisclaimerModal from "../DisclaimerModal";

import S from "./styles";

const InsightReport = () => {
  const { state, dispatch } = useInsightReport();
  const [isExpanded, setExpanded] = usePrintableReportState(
    `insights-expanded-internal`,
    true
  );
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [isShowingSourcingExplainerModal, setIsShowingSourcingExplainerModal] =
    useState(false);
  const isPrintMode = usePrintModeEnabled();
  const { isViewerModeEnabled } = useViewerMode();

  const onToggleDisclaimerModal = () => {
    setIsDisclaimerModalOpen(prev => !prev);
  };

  const onToggleSourcingExplainerModal = () => {
    dispatch({ type: InsightReportActions.hideSourcingExplainer });
    setIsShowingSourcingExplainerModal(prev => !prev);
  };

  const { status, report, errorMessage, isShowingWelcomeDisclaimer } = state;

  const isShowingReport = isShowingInsightsReport(state, report);

  const isShowingGeneratingError =
    status === InsightReportStatus.errorFetchingReport &&
    errorMessage === GetReportErrorCodes.NO_WAM_ERROR;

  const isShowingLoading =
    status === InsightReportStatus.fetching ||
    errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR;

  // As we wait for a "not found" error to determine whether we hide the
  // XI report, the XI section may show until we have that error, which results
  // in a flickering UI for users who don't have XI enabled.
  const reportNotYetFetched =
    errorMessage !== GetReportErrorCodes.IN_PROGRESS_ERROR &&
    !report &&
    (status === InsightReportStatus.idle ||
      status === InsightReportStatus.fetching);

  if (
    errorMessage === GetReportErrorCodes.NOT_FOUND_ERROR ||
    reportNotYetFetched
  ) {
    return null;
  }

  if (status === InsightReportStatus.removed) {
    return null;
  }

  // We don't want to show the Insights section in a report PDF export
  // unless it has loaded successfully.
  if (isPrintMode && !report) {
    return null;
  }

  const showStatus = !isPDX && !isPrintMode && !isViewerModeEnabled;

  return (
    <InsightsFeatureProvider>
      <Section
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        title={isPDX ? "Insights" : "Xapien Insights"}
        headerComponent={showStatus && <StatusNotification />}
      >
        <PopoverModalContextProvider>
          {isShowingGeneratingError ? (
            <S.Panel>
              <ErrorGenerating />
            </S.Panel>
          ) : (
            <>
              {isShowingWelcomeDisclaimer && !isPrintMode && (
                <WelcomeDisclaimer />
              )}
              <S.Panel>
                <S.Banner>
                  <S.BannerTextContainer>
                    <S.LogoContainer>
                      <S.LogoIcon />
                    </S.LogoContainer>
                    <S.DisclaimerContainer>
                      Insights are AI-generated from web and media data. We
                      encourage you to verify the sources.
                      {isPDX ? (
                        <S.DisclaimerButton
                          type="button"
                          onClick={onToggleDisclaimerModal}
                        >
                          <div>Please read this important disclaimer</div>
                          <S.InformationIcon />
                        </S.DisclaimerButton>
                      ) : (
                        <S.EducationModalButton
                          type="button"
                          onClick={onToggleSourcingExplainerModal}
                        >
                          Show me how.
                        </S.EducationModalButton>
                      )}
                    </S.DisclaimerContainer>
                  </S.BannerTextContainer>
                  <S.BannerCta>
                    <MainMenuControl report={report} />
                  </S.BannerCta>
                </S.Banner>
              </S.Panel>
              <S.Divider />
              <S.ContentPanel>
                <S.Container>
                  {isShowingLoading && <LoadingReport />}
                  {isShowingReport && <ReportContent />}
                </S.Container>
              </S.ContentPanel>
            </>
          )}
          {isPDX && (
            <DisclaimerModal
              onToggle={() => setIsDisclaimerModalOpen(prev => !prev)}
              isOpen={isDisclaimerModalOpen}
            />
          )}
          {!isPDX && (
            <SourcingExplainer
              isOpen={isShowingSourcingExplainerModal}
              onClose={() => setIsShowingSourcingExplainerModal(prev => !prev)}
            />
          )}
        </PopoverModalContextProvider>
      </Section>
    </InsightsFeatureProvider>
  );
};

export default InsightReport;
