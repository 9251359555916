import { FetchResult } from "api/types";
import { apm } from "@elastic/apm-rum";
import { getHeaders } from "services/http";
import config from "config";

import { InformationSource } from "api/report/report-types";

import {
  BasicDetailsType,
  BasicDetail
} from "components/molecules/BasicDetails";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

export interface UpcResponse {
  name: {
    text: string;
    sources: InformationSource[];
  };
  jurisdiction: {
    countryCode: string;
    countryName: string;
    sources: InformationSource[];
  };
}

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

export default class UpcReports {
  async getDetails({
    enquiryId,
    shareToken
  }: {
    enquiryId: string;
    shareToken?: string | null;
  }): Promise<FetchResult<BasicDetail[][]>> {
    try {
      const { portalApiUrl } = config;
      let endpoint = `${portalApiUrl}/reports/${enquiryId}/upc-data`;
      if (shareToken) {
        endpoint += `/${shareToken}`;
      }
      const response = await fetch(endpoint, {
        headers: await getHeaders(!shareToken)
      });

      if (!response) {
        throw new Error("No response from UPC");
      }

      const data = (await response.json()) as UpcResponse;

      if (!data) {
        throw new Error("No data from UPC");
      }

      const { name, jurisdiction } = data;

      const upcDetails: BasicDetail[][] = [
        [
          {
            title: "Name",
            itemType: BasicDetailsType.text,
            items: name
              ? [
                  {
                    value: name.text,
                    sources: name.sources
                  }
                ]
              : []
          }
        ],
        [
          {
            title: "Jurisdiction",
            itemType: BasicDetailsType.country,
            items:
              jurisdiction &&
              jurisdiction.countryCode &&
              jurisdiction.countryName
                ? [
                    {
                      code: jurisdiction.countryCode,
                      name: jurisdiction.countryName,
                      sources: jurisdiction.sources
                    }
                  ]
                : []
          }
        ]
      ];

      return { status: true, response: upcDetails };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      console.error(e);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: "Failed to fetch UPC data" };
    }
  }
}
