import { styled } from "@linaria/react";

import { zIndex } from "styles/zIndex";

import { fontSize } from "styles/fonts";
import { grey, purple } from "styles/colors";

import { Carousel as BaseCarousel } from "react-responsive-carousel";
import ButtonNew from "components/atoms/ButtonNew";
import BaseHeading from "components/atoms/Heading";

const Container = styled.div`
  border-radius: 12px;
  position: absolute;
  z-index: ${zIndex.top};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Modal = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 18px;
  overflow: hidden;
  width: 526px;
  padding-bottom: 1rem;
`;

const ModalImage = styled.div`
  min-height: 280px;
  img {
    max-width: 100%;
  }
`;

const ModalContent = styled.div`
  padding: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Heading = styled(BaseHeading)`
  color: #000000;
  font-size: ${fontSize.xl};
`;

const Description = styled.p`
  font-size: ${fontSize.sm};
  color: ${grey.mid42};
  text-align: center;
`;

// @ts-ignore The carousel library we use does not have typescript
const Carousel = styled(BaseCarousel)`
  .slide {
    background: #ffffff;
  }

  .control-dots {
    position: relative;
    padding-top: 1rem;

    .dot {
      background: ${grey.mid42};
      box-shadow: none;

      &.selected,
      &:has(~ .selected) {
        opacity: 1;
        background: ${purple.dark};
      }
    }
  }
`;

const NextButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled(ButtonNew)`
  background: ${purple.dark};
  color: #ffffff;
  padding: 1rem 4rem;

  &:active,
  &:hover,
  &:focus {
    background: ${purple.dark};
  }
`;

const FinishedAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.l7};
`;

const S = {
  FinishedAnimation,
  NextButtonContainer,
  NextButton,
  Carousel,
  Description,
  ModalImage,
  ModalContent,
  Container,
  Modal,
  Heading
};

export default S;
