import React, {
  FC,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo
} from "react";
import { isPDX } from "static-config";
import { useLocation } from "react-router-dom";
import {
  ReportsService,
  RiskSummary,
  RiskSummaryTopic,
  ShareTokenService
} from "api/insights";
import { apm } from "@elastic/apm-rum";
import { RiskSummariesContextValue, RiskSummariesStatus } from "./types";
import RiskSummariesContext from "./context";
import { useEnquiryId } from "../useEnquiryId";

export interface RiskSummariesProviderProps {
  children: ReactNode;
}

const RiskSummariesProvider: FC<RiskSummariesProviderProps> = ({
  children
}) => {
  const [summaries, setSummaries] = useState<RiskSummary[]>([]);
  const [hasRiskSummaries, setHasRiskSummaries] = useState(false);
  const [status, setStatus] = useState(
    isPDX ? RiskSummariesStatus.Loaded : RiskSummariesStatus.NotLoaded
  );
  const location = useLocation();
  const enquiryId = useEnquiryId();

  useEffect(() => {
    // This feature is currently disabled for PDX reports as Xapien Insights
    // does not generate screening summaries for PDX reports.
    if (isPDX) {
      return;
    }

    setStatus(RiskSummariesStatus.Loading);

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    const call = token
      ? ShareTokenService.getReportsRiskSummaries({
          reportId: enquiryId,
          shareToken: token
        })
      : ReportsService.getReportsRiskSummaries({ reportId: enquiryId });

    call
      .then(results => {
        setHasRiskSummaries(true);
        setSummaries(results);
        setStatus(RiskSummariesStatus.Loaded);
      })
      .catch(err => {
        apm.captureError(err);
        console.error("Unable to load Risk Summaries", err);
        setStatus(RiskSummariesStatus.Error);
      });
  }, [location, enquiryId]);

  const getSummary = useCallback(
    (topic: RiskSummaryTopic) =>
      summaries.find(summary => summary.topic === topic),
    [summaries]
  );

  const value: RiskSummariesContextValue = useMemo(
    () => ({
      hasRiskSummaries,
      status,
      getSummary
    }),
    [hasRiskSummaries, getSummary, status]
  );

  return (
    <RiskSummariesContext.Provider value={value}>
      {children}
    </RiskSummariesContext.Provider>
  );
};

export default RiskSummariesProvider;
