/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSectionRequest } from "../models/CreateSectionRequest";
import type { GenerateSuggestionsRequest } from "../models/GenerateSuggestionsRequest";
import type { GenerateSuggestionsResponse } from "../models/GenerateSuggestionsResponse";
import type { MoveSubsectionRequest } from "../models/MoveSubsectionRequest";
import type { ReorderSubsectionsRequest } from "../models/ReorderSubsectionsRequest";
import type { Report } from "../models/Report";
import type { RiskSummary } from "../models/RiskSummary";
import type { SaveQnaRequest } from "../models/SaveQnaRequest";
import type { Section } from "../models/Section";
import type { SourceSubsectionRequest } from "../models/SourceSubsectionRequest";
import type { SubmitFeedbackRequest } from "../models/SubmitFeedbackRequest";
import type { SubmitQnaRequest } from "../models/SubmitQnaRequest";
import type { Subsection } from "../models/Subsection";
import type { TimelineEvent } from "../models/TimelineEvent";
import type { UpdateElementRequest } from "../models/UpdateElementRequest";
import type { UpdateSectionRequest } from "../models/UpdateSectionRequest";
import type { UpdateSubsectionRequest } from "../models/UpdateSubsectionRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ReportsService {
  /**
   * Get an Insights report
   * An endpoint to retrieve an Insights report that has been generate for the specified report.
   * @returns Report Success
   * @throws ApiError
   */
  public static getReports({
    reportId
  }: {
    /**
     * ID of report
     */
    reportId: string;
  }): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Delete an Insights report
   * An endpoint used to delete an Insights report.
   * @returns void
   * @throws ApiError
   */
  public static deleteReports({
    reportId
  }: {
    /**
     * ID of report
     */
    reportId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Ask a question
   * An endpoint used to ask a question on a report.
   * @returns Subsection Success
   * @throws ApiError
   */
  public static postReportsQna({
    reportId,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    requestBody?: SubmitQnaRequest;
  }): CancelablePromise<Subsection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/qna",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Save an answer
   * An endpoint used to save an answer to a report.
   * @returns void
   * @throws ApiError
   */
  public static postReportsQnaAnswersSave({
    reportId,
    answerId,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    /**
     * ID of answer
     */
    answerId: string;
    requestBody?: SaveQnaRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/qna/answers/{answerId}/save",
      path: {
        reportId: reportId,
        answerId: answerId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Generate suggestions for QNA
   * An endpoint used to generate question suggestions for a report.
   * @returns GenerateSuggestionsResponse Success
   * @throws ApiError
   */
  public static postReportsSuggestions({
    reportId,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    requestBody?: GenerateSuggestionsRequest;
  }): CancelablePromise<GenerateSuggestionsResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/suggestions",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Create a new section
   * An endpoint used to create a new section in a report
   * @returns Section Success
   * @throws ApiError
   */
  public static postReportsSections({
    reportId,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    requestBody?: CreateSectionRequest;
  }): CancelablePromise<Section> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/sections",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Update a section's title
   * An endpoint used to update the title of a report section.
   * @returns void
   * @throws ApiError
   */
  public static putReportsSections({
    reportId,
    sectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    requestBody?: UpdateSectionRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/sections/{sectionId}",
      path: {
        reportId: reportId,
        sectionId: sectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Delete a report section
   * An endpoint used to delete a section from a report
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsSections({
    reportId,
    sectionId
  }: {
    reportId: string;
    sectionId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/sections/{sectionId}",
      path: {
        reportId: reportId,
        sectionId: sectionId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Reorder a section's subsections.
   * An endpoint used to reorder the subsections within a section.
   * @returns void
   * @throws ApiError
   */
  public static postReportsSectionsReorder({
    reportId,
    sectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    requestBody?: ReorderSubsectionsRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/sections/{sectionId}/reorder",
      path: {
        reportId: reportId,
        sectionId: sectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Update a subsections's title
   * An endpoint used to update the title of a report subsection.
   * @returns void
   * @throws ApiError
   */
  public static putReportsSectionsSubsections({
    reportId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    requestBody?: UpdateSubsectionRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Delete a report subsection
   * An endpoint used to delete a subsection from a report section
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsSectionsSubsections({
    reportId,
    sectionId,
    subsectionId
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get sourcing for a subsection
   * An endpoint to generate and retrieve the sourcing for a given subsection.
   * @returns Subsection Success
   * @throws ApiError
   */
  public static postReportsSectionsSubsectionsSource({
    reportId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    requestBody?: SourceSubsectionRequest;
  }): CancelablePromise<Subsection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}/source",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Moves a subsection to another section
   * An endpoint used to move a subsection from one section to another.
   * @returns void
   * @throws ApiError
   */
  public static postReportsSectionsSubsectionsMove({
    reportId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    requestBody?: MoveSubsectionRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}/move",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Update an element's title
   * An endpoint used to update the title of a report element.
   * @returns void
   * @throws ApiError
   */
  public static putReportsSectionsSubsectionsElements({
    reportId,
    sectionId,
    subsectionId,
    elementId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
    requestBody?: UpdateElementRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}/elements/{elementId}",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId,
        elementId: elementId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Remove an element
   * An endpoint used to remove an element from a report.
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsSectionsSubsectionsElements({
    reportId,
    sectionId,
    subsectionId,
    elementId
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}/elements/{elementId}",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId,
        elementId: elementId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Submit feedback for a report
   * An endpoint used to submit feedback for an Insights report
   * @returns void
   * @throws ApiError
   */
  public static postReportsFeedback({
    reportId,
    requestBody
  }: {
    reportId: string;
    requestBody?: SubmitFeedbackRequest;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/feedback",
      path: {
        reportId: reportId
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Used to enqueue a report for regeneration.
   * An endpoint to enqueue a xapien insights report to be regenerated.
   * @returns void
   * @throws ApiError
   */
  public static postReportsRegenerate({
    reportId
  }: {
    reportId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/regenerate",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get a timeline
   * An endpoint to retrieve an Insights report's timeline that has been generate for the specified report.
   * @returns TimelineEvent Success
   * @throws ApiError
   */
  public static getReportsTimeline({
    reportId
  }: {
    /**
     * ID of report
     */
    reportId: string;
  }): CancelablePromise<Array<TimelineEvent>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/timeline",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Delete a timeline event
   * An endpoint to delete a specific event from a report's timeline
   * @returns void
   * @throws ApiError
   */
  public static deleteReportsTimelineEvents({
    reportId,
    eventId
  }: {
    /**
     * ID of report
     */
    reportId: string;
    eventId: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/reports/{reportId}/timeline/events/{eventId}",
      path: {
        reportId: reportId,
        eventId: eventId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get risk summaries
   * An endpoint to retrieve risk summaries for a report
   * @returns RiskSummary Success
   * @throws ApiError
   */
  public static getReportsRiskSummaries({
    reportId
  }: {
    /**
     * ID of report
     */
    reportId: string;
  }): CancelablePromise<Array<RiskSummary>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/risk-summaries",
      path: {
        reportId: reportId
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }
}
