import React, { FC, useEffect, useState, ReactNode, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { InformationSource } from "api/report/report-types";
import InsightReportsApi from "api/insight-reports";
import type { SupportingSentence } from "api/insight-reports/types";
import type { Subsection } from "api/insights";

import WithInspector from "components/organisms/WithInspector";
import { deduplicateSources } from "components/organisms/WithInspector/util";
import SourceInspector from "components/organisms/InsightReport/SourceInspector";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useSubjectName } from "util/hooks/useSubjectName";
import useRequestQueue from "util/hooks/useRequestQueue";

import { reportStore } from "state/Store";
import { SupportLevel } from "api/insights";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import ConfidenceIndicator from "./ConfidenceIndicator";

import S from "./styles";

interface Props {
  id: string;
  supportingSentences?: SupportingSentence[];
  children: ReactNode;
  parentIds: string[];
  showSourcing: boolean;
  supportLevel?: SupportLevel;
  isDisclaimer?: boolean;
}

const TextNode: FC<Props> = ({
  id,
  supportingSentences,
  children,
  parentIds,
  showSourcing,
  supportLevel,
  isDisclaimer
}) => {
  const [isFetchingSources, setIsFetchingSources] = useState(false);
  const { addJob } = useRequestQueue();
  const {
    dispatch,
    state: { status }
  } = useInsightReport();
  const reportId = useEnquiryId();
  const subjectName = useSubjectName();
  const [sources, setSources] = useState<InformationSource[]>([]);
  const { search } = useLocation();

  const mapSource = (sourceId: string) => {
    return reportStore.webAndMediaData.find(data => data.sourceId === sourceId);
  };

  const loadSources = useCallback(() => {
    if (!supportingSentences) return;

    const sourcesFromSentences = supportingSentences
      .flatMap(
        ({
          text,
          wamSourceIds,
          supportLevel: sentenceSupportLevel,
          supportStrength,
          contradictionStrength
        }) =>
          wamSourceIds.map(sourceId => {
            const source = mapSource(sourceId);

            if (!source) return undefined;

            return {
              ...source,
              readingTime: undefined,
              subHeading: undefined,
              tags: [],
              text,
              supportDetails: sentenceSupportLevel
                ? {
                    supportLevel: sentenceSupportLevel,
                    supportStrength,
                    contradictionStrength
                  }
                : null
            };
          })
      )
      .filter(Boolean);

    if (sourcesFromSentences.length) {
      setSources(sourcesFromSentences);
    } else {
      setSources([]);
    }
  }, [supportingSentences]);

  useEffect(() => {
    if (supportingSentences && supportingSentences.length > 0) {
      loadSources();
    }
  }, [supportingSentences, loadSources]);

  useEffect(() => {
    if (isFetchingSources && status === InsightReportStatus.idle) {
      setIsFetchingSources(false);
    }
  }, [isFetchingSources, status]);

  const onInspectorOpen = (open: boolean) => {
    if (!open) return;

    if (!supportingSentences) {
      setIsFetchingSources(true);

      dispatch({
        type: InsightReportActions.updatingFetchingSources,
        nodeId: id
      });

      const getSources = async () => {
        const [sectionId, subsectionId] = parentIds;
        const params = new URLSearchParams(search);
        const api = new InsightReportsApi();

        const result = await api.getSources({
          reportId,
          sectionId,
          subsectionId,
          subjectName: subjectName ?? "",
          shareToken: params.get("token")
        });

        if (!result.status) {
          return {
            sectionId,
            subsectionId
          };
        }

        const { response: subSection } = result;

        if (!subSection)
          return {
            sectionId,
            subsectionId
          };

        return {
          sectionId,
          subsectionId,
          subSection
        };
      };

      const updateSources = ({
        sectionId,
        subsectionId,
        subSection
      }: {
        sectionId: string;
        subsectionId: string;
        subSection?: Subsection;
      }) => {
        if (!subSection) {
          dispatch({
            type: InsightReportActions.errorFetchingSources,
            errorFetchingSourcesId: subsectionId
          });
          return;
        }

        dispatch({
          type: InsightReportActions.updateReportSubSection,
          sectionId,
          subsectionId,
          subSection
        });
      };

      addJob({ task: getSources, callback: updateSources });
    } else {
      loadSources();
    }
  };

  if (isDisclaimer) {
    return <S.Disclaimer>{children}</S.Disclaimer>;
  }

  if (!showSourcing) return <>{children}</>;

  if (isFetchingSources) {
    return (
      <WithInspector display="inline" hidePopover showXiReactToolTip>
        {children}
      </WithInspector>
    );
  }

  const dedupedSources = deduplicateSources(sources || []) || [];

  return (
    <SourceInspector
      title={`${dedupedSources.length} Strongest sources`}
      sources={sources}
      display="inline"
      onOpen={onInspectorOpen}
      showXiReactToolTip
    >
      {showSourcing && supportLevel ? (
        <ConfidenceIndicator supportLevel={supportLevel}>
          {children}
        </ConfidenceIndicator>
      ) : (
        <>{children}</>
      )}
    </SourceInspector>
  );
};

export default TextNode;
