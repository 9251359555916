import { ReactElement, MouseEventHandler, CSSProperties, FC } from "react";

export enum ButtonType {
  Filled = "FILLED",
  FilledLight = "FILLEDLIGHT",
  Outlined = "OUTLINED",
  OutlinedLight = "OUTLINEDLIGHT",
  Text = "TEXT"
}

export enum ButtonSize {
  ExtraSmall = "EXTRASMALL",
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE"
}

export interface Props {
  type: ButtonType;
  size: ButtonSize;
  IconLeading?: FC<any>;
  IconTrailing?: FC<any>;
  BadgeTrailing?: ReactElement;
  text: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: CSSProperties;
}
