import { styled } from "@linaria/react";

import Avatar from "components/atoms/Avatar";
import { ChevronDown as ChevronDownSvg } from "react-feather";

import { fontSize } from "styles/fonts";
import { grey, blue, standardColors } from "styles/colors";

// Referenced below
const CustomAvatar = styled(Avatar)`
  background: transparent !important;
`;

const ChevronDown = styled(ChevronDownSvg)`
  --icon-width-height: 1rem;
  width: var(--icon-width-height);
  height: var(--icon-width-height);
  stroke: ${standardColors.white};
`;

const ButtonText = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  line-height: 1.125rem;
`;

const ProfileButton = styled.button<{ selected: boolean }>`
  all: unset;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${standardColors.white};
  flex-direction: row;
  align-items: center;
  min-width: 8.125rem;
  padding: 0.5rem 0.5rem 0.5rem 0.375rem;
  gap: 0.5rem;
  border-radius: 100px;
  transition: background-color 0.3s;
  background-color: ${props =>
    props.selected ? standardColors.white : "transparent"};
  cursor: pointer;

  ${ButtonText} {
    color: ${props =>
      props.selected ? blue.xapienDark : standardColors.white};
  }

  ${ChevronDown} {
    stroke: ${props =>
      props.selected ? blue.xapienDark : standardColors.white};
  }

  ${CustomAvatar} {
    path {
      fill: ${props =>
        props.selected ? blue.xapienDark : standardColors.white};
    }

    rect {
      stroke: ${props =>
        props.selected ? blue.xapienDark : standardColors.white};
    }
  }

  &:hover,
  &:focus-visible {
    background-color: ${grey.dark};

    ${ButtonText} {
      color: ${standardColors.white};
    }

    ${ChevronDown} {
      stroke: ${standardColors.white};
    }

    ${CustomAvatar} {
      path {
        fill: ${standardColors.white};
      }
      rect {
        stroke: ${standardColors.white};
      }
    }
  }

  &:active {
    background-color: ${grey.ghost};
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
    background-color: ${grey.mid};
    border-color: ${grey.ghost};

    ${ButtonText} {
      color: ${grey.ghost};
    }

    ${ChevronDown} {
      stroke: ${grey.ghost};
    }

    ${CustomAvatar} {
      path {
        fill: ${grey.ghost};
      }
      rect {
        stroke: ${grey.ghost};
      }
    }
  }
`;

const ProfileButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const S = {
  ProfileButton,
  ButtonText,
  ChevronDown,
  CustomAvatar,
  ProfileButtonContent
};

export default S;
