export enum TagId {
  GroupMember = "groupMember",
  GroupRole = "groupRole",
  SharedGroup = "sharedGroup",
  SharingStatus = "sharingStatus",
  UserRole = "userRole"
}

export interface Tag {
  id: TagId;
  name: string;
}

interface GroupPermissions {
  canView: boolean;
  canEdit: boolean;
  canViewMembers: boolean;
  canEditMembers: boolean;
  canEditAdmins: boolean;
  canLeave: boolean;
  canViewReports: boolean;
  canDelete: boolean;
}

export interface Group {
  id: string;
  icon: string;
  title: string;
  context?: string;
  description: string;
  tags: Tag[];
  permissions: GroupPermissions;
  memberCount: number;
}

export enum GroupUserRole {
  Admin = "admin",
  Standard = "standard"
}

export interface GroupUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  groupRole: GroupUserRole;
}
