import config from "../config";
import { stage } from "./stage";
import { tenant } from "../static-config";

const { environmentUrl } = config;
// new route that will supplant public API in due course
const apiGatewayPrefix = `https://api2.${environmentUrl}/`;
const imagesApiGatewayPrefix = `https://images.${environmentUrl}/`;
const portalApiPrefix = `https://api.portal.${stage}.xapien.com/`;

const wsApiGatewayPrefix = `wss://wsapi4.${stage}.app.digital-insight.com/`;
// TODO - point to a sensible URL once back-end is productionised
const automatedTestingApiGatewayPrefix = `https://1uk6pq80y4.execute-api.us-east-1.amazonaws.com/dev/`;

const endpoints = {
  listReports: `${apiGatewayPrefix}list-reports`,
  regenerateReport: `${apiGatewayPrefix}regenerate-report`,
  startEnquiry: `${apiGatewayPrefix}start-enquiry`,
  enquiryStatus: `${apiGatewayPrefix}get-enquiry-status`,
  getCommunities: `${apiGatewayPrefix}get-community-ids`,
  setCommunities: `${apiGatewayPrefix}set-community-ids`,
  diagnosticsOverview: `${apiGatewayPrefix}get-enquiry-status`,
  diagnosticsContent: `${apiGatewayPrefix}get-enquiry-content-status`,
  features: `${apiGatewayPrefix}available-features`,
  searchInputValidation: `${apiGatewayPrefix}validate`,
  getReportPDF: `${apiGatewayPrefix}get-report-pdf2`,
  getReportUrl: `${apiGatewayPrefix}get-report-url`,
  wsEndpoint: wsApiGatewayPrefix,
  serveImage: `${imagesApiGatewayPrefix}serve-image/${tenant.toLowerCase()}`,
  assertion: `${automatedTestingApiGatewayPrefix}assertion`,
  testcaseFromReport: `${automatedTestingApiGatewayPrefix}testcase-from-report`,
  validateReport: `${automatedTestingApiGatewayPrefix}validate-report`,
  platformAlert: `${apiGatewayPrefix}platform-alert`,
  setRiskAssertions: `${apiGatewayPrefix}set-risk-assertions`,
  getUserNotes: `${portalApiPrefix}reports`,
  setUserNotes: `${portalApiPrefix}reports`
};

export default endpoints;
