import React, {
  useMemo,
  useCallback,
  useReducer,
  useEffect,
  FC,
  ReactNode
} from "react";

import { useLocation } from "react-router-dom";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import useRequestQueue from "util/hooks/useRequestQueue";

import InsightReportsApi, { GetReportErrorCodes } from "api/insight-reports";

import type { InsightReportAction } from "./types";
import { InsightReportStatus, InsightReportActions } from "./types";

import {
  InsightReportContext,
  insightReportReducer,
  initialState
} from "./context";

import {
  getReorderedSubsections,
  removeSectionFromReportBySectionId,
  removeSubSectionFromReportBySubSectionId,
  removeElementFromReportByElementId,
  moveSubSectionToAnotherSection
} from "./utils";

interface Props {
  children: ReactNode;
}

export const InsightReportProvider: FC<Props> = ({ children }) => {
  const { addJob } = useRequestQueue();

  const enquiryId = useEnquiryId();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [state, reducerDispatch] = useReducer(
    insightReportReducer,
    initialState
  );

  const dispatch = useCallback((action: InsightReportAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.idle &&
      state.report === undefined
    ) {
      dispatch({ type: InsightReportActions.fetchReport });
    }
  }, [state.status, state.report, dispatch]);

  useEffect(() => {
    if (state.status === InsightReportStatus.regeneratingReport) {
      const api = new InsightReportsApi();

      api
        .regenerateReport({ reportId: enquiryId })
        .then(() => {
          dispatch({ type: InsightReportActions.fetchReport });
        })
        .catch(() =>
          dispatch({ type: InsightReportActions.errorFetchingReport })
        );
    }
  }, [state.status, dispatch, enquiryId]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.fetching &&
      state.report === undefined
    ) {
      const api = new InsightReportsApi();

      let apiCall = () => api.getReport({ id: enquiryId });

      if (params.has("token")) {
        apiCall = () =>
          api.getReportWithShareToken({
            id: enquiryId,
            shareToken: params.get("token")!
          });
      }

      apiCall()
        .then(({ response: rawReport, message }) => {
          if (rawReport) {
            dispatch({
              type: InsightReportActions.updateReport,
              rawReport,
              reportId: enquiryId
            });
          } else {
            dispatch({
              type: InsightReportActions.errorFetchingReport,
              errorMessage: message
            });
          }
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: InsightReportActions.errorFetchingReport });
        });
    }
  }, [state.status, state.report, dispatch, enquiryId, params]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.errorFetchingReport &&
      state.errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR
    ) {
      setTimeout(() => {
        dispatch({ type: InsightReportActions.fetchReport });
      }, 10000);
    }

    if (
      state.status === InsightReportStatus.removingReportSection &&
      state.sectionIdToRemove
    ) {
      const api = new InsightReportsApi();

      api
        .removeSection({
          reportId: enquiryId,
          sectionId: state.sectionIdToRemove
        })
        .then(response => {
          if (response.status) {
            if (!state.report || !state.rawReport || !state.sectionIdToRemove)
              return;

            const removedSectionIndex = state.report.sections.findIndex(
              s => s.id === state.sectionIdToRemove
            );

            const previousSection =
              state.report.sections[removedSectionIndex - 1];

            const rawReport = removeSectionFromReportBySectionId(
              state.rawReport,
              state.sectionIdToRemove
            );

            dispatch({ type: InsightReportActions.removeReportSectionSuccess });

            setTimeout(() => {
              dispatch({
                type: InsightReportActions.updateReport,
                reportId: enquiryId,
                rawReport,
                activeSectionSlug: previousSection?.id
              });
            }, 2500);
          } else {
            dispatch({ type: InsightReportActions.errorRemovingReportSection });
          }
        });
    }
  }, [
    state.status,
    state.report,
    state.rawReport,
    state.errorMessage,
    state.sectionIdToRemove,
    dispatch,
    enquiryId
  ]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.removingReportSubSection &&
      state.sectionIdToRemove &&
      state.subsectionIdToRemove
    ) {
      const api = new InsightReportsApi();

      api
        .removeSubSection({
          reportId: enquiryId,
          sectionId: state.sectionIdToRemove,
          subsectionId: state.subsectionIdToRemove
        })
        .then(response => {
          if (response.status) {
            if (
              !state.report ||
              !state.rawReport ||
              !state.sectionIdToRemove ||
              !state.subsectionIdToRemove
            )
              return;

            const rawReport = removeSubSectionFromReportBySubSectionId(
              state.rawReport,
              state.subsectionIdToRemove
            );

            dispatch({ type: InsightReportActions.removeReportSectionSuccess });

            setTimeout(() => {
              dispatch({
                type: InsightReportActions.updateReport,
                reportId: enquiryId,
                rawReport
              });
            }, 3000);
          } else {
            dispatch({ type: InsightReportActions.errorRemovingReportSection });
          }
        });
    }
  }, [
    state.status,
    state.report,
    state.rawReport,
    state.sectionIdToRemove,
    state.subsectionIdToRemove,
    dispatch,
    enquiryId
  ]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.removingReportElement &&
      state.sectionIdToRemove &&
      state.subsectionIdToRemove &&
      state.elementIdToRemove
    ) {
      const api = new InsightReportsApi();

      api
        .removeElement({
          reportId: enquiryId,
          sectionId: state.sectionIdToRemove,
          subsectionId: state.subsectionIdToRemove,
          elementId: state.elementIdToRemove
        })
        .then(response => {
          if (response.status) {
            if (
              !state.report ||
              !state.rawReport ||
              !state.sectionIdToRemove ||
              !state.subsectionIdToRemove ||
              !state.elementIdToRemove
            )
              return;

            const rawReport = removeElementFromReportByElementId(
              state.rawReport,
              state.elementIdToRemove
            );

            dispatch({ type: InsightReportActions.removeReportSectionSuccess });

            setTimeout(() => {
              dispatch({
                type: InsightReportActions.updateReport,
                reportId: enquiryId,
                rawReport
              });
            }, 3000);
          } else {
            dispatch({ type: InsightReportActions.errorRemovingReportSection });
          }
        });
    }
  }, [
    state.status,
    state.report,
    state.rawReport,
    state.sectionIdToRemove,
    state.subsectionIdToRemove,
    state.elementIdToRemove,
    dispatch,
    enquiryId
  ]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.movingSubSection &&
      state.destinationSectionId &&
      state.currentSubSectionId &&
      state.currentSectionId
    ) {
      const api = new InsightReportsApi();

      api
        .moveSubSection({
          reportId: enquiryId,
          sectionId: state.currentSectionId,
          subsectionId: state.currentSubSectionId,
          requestBody: {
            destinationSectionId: state.destinationSectionId
          }
        })
        .then(response => {
          if (response.status) {
            if (
              !state.report ||
              !state.rawReport ||
              !state.currentSectionId ||
              !state.currentSubSectionId ||
              !state.destinationSectionId
            )
              return;

            const rawReport = moveSubSectionToAnotherSection(
              state.rawReport,
              state.currentSectionId,
              state.currentSubSectionId,
              state.destinationSectionId
            );

            dispatch({ type: InsightReportActions.moveSubsectionSuccess });

            setTimeout(() => {
              dispatch({
                type: InsightReportActions.updateReport,
                reportId: enquiryId,
                rawReport
              });
            }, 3000);
          } else {
            dispatch({ type: InsightReportActions.errorMovingSubSection });
          }
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: InsightReportActions.errorMovingSubSection });
        });
    }
  }, [
    state.status,
    state.report,
    state.rawReport,
    state.currentSectionId,
    state.currentSubSectionId,
    state.destinationSectionId,
    dispatch,
    enquiryId
  ]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.reorderingSubSections &&
      state.reorderingSectionId &&
      state.reorderingSubsectionId &&
      state.reorderingSubsectionDirection
    ) {
      const api = new InsightReportsApi();

      const reorderingSection = state.rawReport?.sections.find(
        s => s.id === state.reorderingSectionId
      );

      if (!reorderingSection) return;

      const reorderedSubsections = getReorderedSubsections({
        reorderDirection: state.reorderingSubsectionDirection,
        subsectionIdToMove: state.reorderingSubsectionId,
        subsections: reorderingSection.subsections
      });

      const subsectionIds = reorderedSubsections.map(s => s.id);

      dispatch({ type: InsightReportActions.reorderSubSectionsSuccess });

      api
        .reorderSubSections({
          reportId: enquiryId,
          sectionId: state.reorderingSectionId,
          requestBody: {
            subsectionIds
          }
        })
        .then(response => {
          if (response.status) {
            if (
              !state.report ||
              !state.rawReport ||
              !state.reorderingSectionId ||
              !state.reorderingSubsectionDirection ||
              !state.reorderingSubsectionId
            )
              return;

            const updatedSections = state.rawReport.sections.map(section =>
              section.id === state.reorderingSectionId
                ? { ...section, subsections: reorderedSubsections }
                : section
            );

            const rawReport = { ...state.rawReport, sections: updatedSections };

            setTimeout(() => {
              dispatch({
                type: InsightReportActions.updateReport,
                reportId: enquiryId,
                rawReport
              });
            }, 1500);
          } else {
            dispatch({ type: InsightReportActions.errorReOrderingSubSection });
          }
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: InsightReportActions.errorReOrderingSubSection });
        });
    }
  }, [
    state.status,
    state.report,
    state.rawReport,
    state.reorderingSectionId,
    state.reorderingSubsectionId,
    state.reorderingSubsectionDirection,
    dispatch,
    enquiryId
  ]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.savingTitle &&
      state.updatedTitleSectionId &&
      state.updatedTitle
    ) {
      const updateTitle = async () => {
        if (!state.updatedTitleSectionId) return;
        if (!state.updatedTitle) return;

        const api = new InsightReportsApi();

        await api.updateSectionTitle({
          reportId: enquiryId,
          sectionId: state.updatedTitleSectionId,
          subsectionId: state.updatedTitleSubsectionId,
          elementId: state.updatedTitleElementId,
          title: state.updatedTitle
        });
      };

      addJob({
        task: updateTitle,
        callback: () =>
          dispatch({ type: InsightReportActions.resetEditingTitle })
      });
    }
  }, [
    state.status,
    state.updatedTitleSectionId,
    state.updatedTitleSubsectionId,
    state.updatedTitleElementId,
    state.updatedTitle,
    dispatch,
    enquiryId,
    addJob
  ]);

  useEffect(() => {
    if (
      state.status === InsightReportStatus.addingNewSection &&
      state.updatedTitleSectionId
    ) {
      const api = new InsightReportsApi();

      api
        .addNewSection({
          reportId: enquiryId,
          sectionId: state.updatedTitleSectionId
        })
        .then(({ response }) => {
          if (response) {
            dispatch({
              type: InsightReportActions.addNewSectionSuccess
            });
          } else {
            dispatch({
              type: InsightReportActions.errorAddingNewSection
            });
          }
        })
        .catch(() =>
          dispatch({
            type: InsightReportActions.errorAddingNewSection
          })
        );
    }
  }, [state.status, state.updatedTitleSectionId, dispatch, enquiryId]);

  return (
    <InsightReportContext.Provider value={providerValue}>
      {children}
    </InsightReportContext.Provider>
  );
};
