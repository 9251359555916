/* eslint-disable @typescript-eslint/no-unused-vars */
import { FetchResult } from "api/types";
import { ApiError } from "api/old-insights";
import {
  ReportsService,
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection,
  ShareTokenService,
  MoveSubsectionRequest,
  ReorderSubsectionsRequest,
  TimelineEvent,
  SectionIcon
} from "api/insights";
import { getErrorMessage } from "util/error-utils";
import { apm } from "@elastic/apm-rum";
import { QuestionAndAnswer, Subsection } from "./types";

export enum GetReportErrorCodes {
  IN_PROGRESS_ERROR = "INSIGHTS_REPORT_IN_PROGRESS",
  NOT_FOUND_ERROR = "INSIGHTS_REPORT_NOT_FOUND",
  FAILED_GENERATING_ERROR = "INSIGHTS_REPORT_FAILED",
  NO_WAM_ERROR = "INSIGHTS_REPORT_NO_WAM",
  GENERIC_ERROR = "INTERNAL_SERVER_ERROR"
}

export enum GetTimelineErrorCodes {
  IN_PROGRESS_ERROR = "TIMELINE_IN_PROGRESS",
  NOT_FOUND_ERROR = "TIMELINE_NOT_FOUND",
  FAILED_ERROR = "TIMELINE_FAILED",
  GENERIC_ERROR = "INTERNAL_SERVER_ERROR"
}

export type {
  H1ContentNode,
  H2ContentNode,
  H3ContentNode,
  H4ContentNode,
  PContentNode,
  DivContentNode,
  TextContentNode,
  ContentNode,
  InsightReportSection,
  InsightReport
} from "./types";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

export default class InsightReports {
  async getSuggestedQuestions({
    id: reportId,
    subjectName
  }: {
    id: string;
    subjectName: string;
  }): Promise<FetchResult<string[]>> {
    try {
      const { suggestions } = await ReportsService.postReportsSuggestions({
        reportId,
        requestBody: { subject_name: subjectName }
      });

      return {
        status: true,
        response: suggestions
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getSuggestedQuestionsWithShareToken({
    id: reportId,
    subjectName,
    shareToken
  }: {
    id: string;
    subjectName: string;
    shareToken: string;
  }): Promise<FetchResult<string[]>> {
    try {
      const { suggestions } = await ShareTokenService.postReportsSuggestions({
        reportId,
        requestBody: { subject_name: subjectName },
        shareToken
      });

      return {
        status: true,
        response: suggestions
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getReport({
    id: reportId
  }: {
    id: string;
  }): Promise<FetchResult<RawReport>> {
    try {
      const response = await ReportsService.getReports({ reportId });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async getReportWithShareToken({
    id: reportId,
    shareToken
  }: {
    id: string;
    shareToken: string;
  }): Promise<FetchResult<RawReport>> {
    try {
      const response = await ShareTokenService.getReports({
        reportId,
        shareToken
      });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async getSources({
    reportId,
    sectionId,
    subsectionId,
    subjectName,
    shareToken
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    subjectName: string;
    shareToken: string | null;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const requestBody = {
        subject_name: subjectName
      };
      const response = shareToken
        ? await ShareTokenService.postReportsSectionsSubsectionsSource({
            reportId,
            sectionId,
            subsectionId,
            requestBody,
            shareToken
          })
        : await ReportsService.postReportsSectionsSubsectionsSource({
            reportId,
            sectionId,
            subsectionId,
            requestBody
          });

      return { status: true, response };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getSourcesWithShareToken({
    reportId,
    sectionId,
    subsectionId,
    subjectName,
    shareToken
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    subjectName: string;
    shareToken: string;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const requestBody = {
        subject_name: subjectName
      };
      const response =
        await ShareTokenService.postReportsSectionsSubsectionsSource({
          reportId,
          sectionId,
          subsectionId,
          requestBody,
          shareToken
        });

      return { status: true, response };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getQuestionsAndAnswers({
    enquiryId
  }: {
    enquiryId: string;
  }): Promise<FetchResult<QuestionAndAnswer[]>> {
    try {
      console.warn(`Get questionsAndAnswers Not implemented: ${enquiryId}`);

      return { status: true, response: [] };
    } catch (e) {
      apm.captureError(e as Error);
      if (e instanceof ApiError && e.status === 404) {
        return {
          status: true,
          response: []
        };
      }

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async askSourcedQuestion({
    enquiryId,
    question,
    subjectName,
    shareToken
  }: {
    enquiryId: string;
    question: string;
    subjectName: string;
    shareToken: string | null;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const response = shareToken
        ? await ShareTokenService.postReportsQna({
            reportId: enquiryId,
            requestBody: { subject_name: subjectName, question },
            shareToken
          })
        : await ReportsService.postReportsQna({
            reportId: enquiryId,
            requestBody: { subject_name: subjectName, question }
          });

      return {
        status: true,
        response
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async addSourcesToElement(
    _sectionId: string,
    _enquiryId: string,
    _subjectName: string
  ): Promise<FetchResult<Subsection>> {
    try {
      return {
        status: true,
        response: {
          id: "",
          title: "",
          elements: []
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async provideFeedback({
    id,
    subjectName,
    feedbackText,
    section,
    subsection
  }: {
    id: string;
    subjectName: string;
    feedbackText: string;
    section?: string;
    subsection?: string;
  }): Promise<FetchResult> {
    try {
      const requestBody = {
        subject_name: subjectName,
        message: feedbackText,
        section,
        subsection
      };
      await ReportsService.postReportsFeedback({ reportId: id, requestBody });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeSection({
    reportId,
    sectionId
  }: {
    reportId: string;
    sectionId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReportsSections({
        reportId,
        sectionId
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeSubSection({
    reportId,
    sectionId,
    subsectionId
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReportsSectionsSubsections({
        reportId,
        sectionId,
        subsectionId
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeElement({
    reportId,
    sectionId,
    subsectionId,
    elementId
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReportsSectionsSubsectionsElements({
        reportId,
        sectionId,
        subsectionId,
        elementId
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async moveSubSection({
    reportId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    requestBody: MoveSubsectionRequest;
  }): Promise<FetchResult> {
    try {
      await ReportsService.postReportsSectionsSubsectionsMove({
        reportId,
        sectionId,
        subsectionId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async reorderSubSections({
    reportId,
    sectionId,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    requestBody: ReorderSubsectionsRequest;
  }): Promise<FetchResult> {
    try {
      await ReportsService.postReportsSectionsReorder({
        reportId,
        sectionId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeReport({ reportId }: { reportId: string }): Promise<FetchResult> {
    try {
      await ReportsService.deleteReports({ reportId });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async regenerateReport({
    reportId
  }: {
    reportId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.postReportsRegenerate({ reportId });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async addToSection({
    reportId,
    answerId,
    sectionId
  }: {
    reportId: string;
    answerId: string;
    sectionId: string;
  }): Promise<FetchResult> {
    try {
      await ReportsService.postReportsQnaAnswersSave({
        reportId,
        answerId,
        requestBody: { sectionId }
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getTimeline({
    id: reportId
  }: {
    id: string;
  }): Promise<FetchResult<TimelineEvent[]>> {
    try {
      const response = await ReportsService.getReportsTimeline({ reportId });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async removeReportTimelineEvent({
    reportId,
    eventId
  }: {
    reportId: string;
    eventId: string;
  }): Promise<FetchResult<TimelineEvent[]>> {
    try {
      await ReportsService.deleteReportsTimelineEvents({
        reportId,
        eventId
      });

      return { status: true };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetTimelineErrorCodes.GENERIC_ERROR };
    }
  }

  async getTimelineWithShareToken({
    id: reportId,
    shareToken
  }: {
    id: string;
    shareToken: string;
  }): Promise<FetchResult<TimelineEvent[]>> {
    try {
      const response = await ShareTokenService.getReportsTimeline({
        reportId,
        shareToken
      });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async updateSectionTitle({
    reportId,
    sectionId,
    subsectionId,
    elementId,
    title
  }: {
    reportId: string;
    sectionId: string;
    subsectionId?: string;
    elementId?: string;
    title: string;
  }): Promise<FetchResult> {
    try {
      const requestBody = {
        title
      };

      if (elementId && subsectionId) {
        await ReportsService.putReportsSectionsSubsectionsElements({
          reportId,
          sectionId,
          subsectionId,
          elementId,
          requestBody
        });

        return { status: true };
      }

      if (subsectionId) {
        await ReportsService.putReportsSectionsSubsections({
          reportId,
          sectionId,
          subsectionId,
          requestBody
        });

        return { status: true };
      }

      await ReportsService.putReportsSections({
        reportId,
        sectionId,
        requestBody
      });

      return { status: true };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async addNewSection({
    reportId,
    sectionId
  }: {
    reportId: string;
    sectionId: string;
  }): Promise<FetchResult<RawSection>> {
    try {
      const requestBody = { sectionId };
      const response = await ReportsService.postReportsSections({
        reportId,
        requestBody
      });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }
}
