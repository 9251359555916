import React, { FC, useState } from "react";

import { useAuthentication } from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";

import LoginForm from "./LoginForm";
import ForgottenPassword from "./ForgottenPassword";
import MfaForm from "./MfaForm";

import S from "./styles";

interface Props {
  slogan?: string;
  isOpen: boolean;
}

const LoginModal: FC<Props> = ({ isOpen, slogan }) => {
  const { state } = useAuthentication();
  const [showForgottenPassword, setShowForgottenPassword] = useState(false);

  const onForgottenPasswordToggle = () =>
    setShowForgottenPassword(prev => !prev);

  const isLoggingIn = [
    AuthenticationStatus.unauthenticated,
    AuthenticationStatus.verifying,
    AuthenticationStatus.unknown,
    AuthenticationStatus.authenticating,
    AuthenticationStatus.fetchingLoginInfo,
    AuthenticationStatus.passwordRequired
  ].includes(state.status);

  const isVerifyingMfa =
    [
      AuthenticationStatus.passwordVerified,
      AuthenticationStatus.authenticated,
      AuthenticationStatus.authenticating
    ].includes(state.status) && state.mfaStatus;

  const renderModalContent = () => {
    if (showForgottenPassword) {
      return <ForgottenPassword onBackClick={onForgottenPasswordToggle} />;
    }

    if (isLoggingIn) {
      return (
        <LoginForm
          slogan={slogan}
          onForgottenPasswordClick={onForgottenPasswordToggle}
        />
      );
    }

    if (isVerifyingMfa) {
      return <MfaForm />;
    }

    return null;
  };

  return (
    <S.CustomModalContainer toggleOpen={() => null} isOpen={isOpen} width={540}>
      {renderModalContent()}
    </S.CustomModalContainer>
  );
};

export default LoginModal;
