/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateSuggestionsRequest } from "../models/GenerateSuggestionsRequest";
import type { GenerateSuggestionsResponse } from "../models/GenerateSuggestionsResponse";
import type { Report } from "../models/Report";
import type { RiskSummary } from "../models/RiskSummary";
import type { SourceSubsectionRequest } from "../models/SourceSubsectionRequest";
import type { SubmitQnaRequest } from "../models/SubmitQnaRequest";
import type { Subsection } from "../models/Subsection";
import type { TimelineEvent } from "../models/TimelineEvent";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ShareTokenService {
  /**
   * Get an Insights report with a share token
   * An endpoint to retrieve an Insights report that has been generate for the specified report.
   * @returns Report Success
   * @throws ApiError
   */
  public static getReports({
    reportId,
    shareToken
  }: {
    /**
     * ID of report
     */
    reportId: string;
    /**
     * Report share token
     */
    shareToken: string;
  }): CancelablePromise<Report> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/{shareToken}",
      path: {
        reportId: reportId,
        shareToken: shareToken
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Ask a question
   * An endpoint used to ask a question on a report.
   * @returns Subsection Success
   * @throws ApiError
   */
  public static postReportsQna({
    reportId,
    shareToken,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    /**
     * Report share token
     */
    shareToken: string;
    requestBody?: SubmitQnaRequest;
  }): CancelablePromise<Subsection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/qna/{shareToken}",
      path: {
        reportId: reportId,
        shareToken: shareToken
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Generate suggestions for QNA
   * An endpoint used to generate question suggestions for a report.
   * @returns GenerateSuggestionsResponse Success
   * @throws ApiError
   */
  public static postReportsSuggestions({
    reportId,
    shareToken,
    requestBody
  }: {
    /**
     * ID of report
     */
    reportId: string;
    /**
     * Report share token
     */
    shareToken: string;
    requestBody?: GenerateSuggestionsRequest;
  }): CancelablePromise<GenerateSuggestionsResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/suggestions/{shareToken}",
      path: {
        reportId: reportId,
        shareToken: shareToken
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get sourcing for a subsection with a share token
   * An endpoint to generate and retrieve the sourcing for a given subsection.
   * @returns Subsection Success
   * @throws ApiError
   */
  public static postReportsSectionsSubsectionsSource({
    reportId,
    sectionId,
    subsectionId,
    shareToken,
    requestBody
  }: {
    reportId: string;
    sectionId: string;
    subsectionId: string;
    shareToken: string;
    requestBody?: SourceSubsectionRequest;
  }): CancelablePromise<Subsection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/{reportId}/sections/{sectionId}/subsections/{subsectionId}/source/{shareToken}",
      path: {
        reportId: reportId,
        sectionId: sectionId,
        subsectionId: subsectionId,
        shareToken: shareToken
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get a timeline
   * An endpoint to retrieve an Insights report's timeline that has been generate for the specified report.
   * @returns TimelineEvent Success
   * @throws ApiError
   */
  public static getReportsTimeline({
    reportId,
    shareToken
  }: {
    /**
     * ID of report
     */
    reportId: string;
    shareToken: string;
  }): CancelablePromise<Array<TimelineEvent>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/timeline/{shareToken}",
      path: {
        reportId: reportId,
        shareToken: shareToken
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }

  /**
   * Get risk summaries
   * An endpoint to retrieve risk summaries for a report
   * @returns RiskSummary Success
   * @throws ApiError
   */
  public static getReportsRiskSummaries({
    reportId,
    shareToken
  }: {
    /**
     * ID of report
     */
    reportId: string;
    shareToken: string;
  }): CancelablePromise<Array<RiskSummary>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/{reportId}/risk-summaries/{shareToken}",
      path: {
        reportId: reportId,
        shareToken: shareToken
      },
      errors: {
        400: `Validation`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Report Not Found`,
        409: `Report Failed`,
        423: `Report In Progress`,
        500: `Internal Server Error`
      }
    });
  }
}
