import React, { ReactNode, useState, useContext, KeyboardEvent } from "react";

import { Minus, Check, X } from "react-feather";

import { inject, observer } from "mobx-react";

import { isPDX } from "static-config";

import { ReactComponent as WrongPersonSvg } from "img/icons/wrong-person.svg";

import DisabledConfirmingModal from "components/molecules/DisabledConfirmingModal";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import { EntityTypeContext } from "util/context/EntityTypeContext";
import usePdxDiagnosticsMode from "util/hooks/usePdxDiagnosticsMode";

import {
  AssessmentOptions,
  convertOldToNew
} from "pages/report/AssessmentOptions";
import useViewerMode from "util/hooks/useViewerMode";
import ReportStore from "state/ReportStore";

import S from "./styles";

type AssessmentOption =
  (typeof AssessmentOptions)[keyof typeof AssessmentOptions];

interface Option {
  value: AssessmentOption;
  label: ReactNode;
}

interface Props {
  onUserAssessment: (value: string) => void;
  userAssessment: AssessmentOption | number;
  goldStandardAssessment?: AssessmentOption;
  diagnosticsModeEnabled?: boolean;
  reportStore: ReportStore;
  isDisregarded?: boolean;
}

const WrongButtonIcon = ({
  isActive = false,
  appearsDisabled = false
}: {
  isActive?: boolean;
  appearsDisabled?: boolean;
}) => {
  return (
    <S.ButtonIcon isActive={isActive} appearsDisabled={appearsDisabled}>
      <Minus />
    </S.ButtonIcon>
  );
};

const RightButtonIcon = ({
  isActive = false,
  appearsDisabled = false
}: {
  isActive?: boolean;
  appearsDisabled?: boolean;
}) => {
  return (
    <S.ButtonIcon isActive={isActive} appearsDisabled={appearsDisabled}>
      <Check />
    </S.ButtonIcon>
  );
};

const AssessmentSelectionControl = observer(
  ({
    onUserAssessment,
    userAssessment,
    goldStandardAssessment,
    diagnosticsModeEnabled,
    reportStore,
    isDisregarded = false
  }: Props) => {
    const subjectType = useContext(EntityTypeContext).toLowerCase();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDiscarded, setIsDiscarded] = useState(false);
    const { isViewerModeEnabled } = useViewerMode();
    const { enabled: pdxDiagnosticsEnabled } = usePdxDiagnosticsMode();
    const value =
      typeof userAssessment === "string"
        ? userAssessment
        : convertOldToNew(userAssessment);

    const onSelectChange = async (option: Option) =>
      onUserAssessment(option.value);

    const onButtonClick = () => {
      if (isPDX) return;
      if (value === AssessmentOptions.NoUserAssessment) {
        onUserAssessment(AssessmentOptions.RightPerson);
      } else {
        onUserAssessment(AssessmentOptions.NoUserAssessment);
      }
    };

    const isShowingCorrectView =
      isDisregarded || value === AssessmentOptions.RightPerson;

    const renderActionTitle = () => {
      if (isDisregarded && AssessmentOptions.NoUserAssessment) {
        return `Correct ${subjectType}?`;
      }

      if (value === AssessmentOptions.RightPerson) {
        return `Correct ${subjectType}`;
      }

      if (value === AssessmentOptions.WrongPerson) {
        return `Wrong ${subjectType}`;
      }

      return `Wrong ${subjectType}?`;
    };

    const getButtonIcon = () => {
      if (isShowingCorrectView) {
        return <RightButtonIcon isActive appearsDisabled={isPDX} />;
      }

      if (value === AssessmentOptions.WrongPerson) {
        return <WrongButtonIcon isActive appearsDisabled={isPDX} />;
      }

      if (
        goldStandardAssessment &&
        goldStandardAssessment !== AssessmentOptions.NoUserAssessment &&
        diagnosticsModeEnabled
      ) {
        if (goldStandardAssessment === AssessmentOptions.RightPerson) {
          return <RightButtonIcon isActive appearsDisabled={isPDX} />;
        }
        return <WrongButtonIcon isActive appearsDisabled={isPDX} />;
      }

      return <WrongButtonIcon isActive appearsDisabled={isPDX} />;
    };

    const buttonIcon = getButtonIcon();

    const selectionMadeClass =
      value === AssessmentOptions.NoUserAssessment ? "" : "selection-made";

    const disabled = isViewerModeEnabled || reportStore.isReportSaving;

    const getDisabledTooltipString = () => {
      if (reportStore.isReportSaving) {
        return "Save in progress...";
      }

      if (isViewerModeEnabled) {
        return "Confirm/discard is disabled in viewer mode.";
      }

      return "Confirm/discard is disabled";
    };

    const renderDropdown = () => {
      const onHideDiscardModal = () => {
        setIsDropdownOpen(false);
      };

      const onDropdownClick = () => {
        setIsDropdownOpen(true);
      };

      const onKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === "Enter") {
          setIsDropdownOpen(true);
        }
      };

      const onDiscard = () => {
        onSelectChange({
          value: AssessmentOptions.WrongPerson,
          label: `Wrong ${subjectType}`
        });
        setIsDropdownOpen(false);
        setIsDiscarded(true);
      };

      const onConfirm = () => {
        onSelectChange({
          value: AssessmentOptions.RightPerson,
          label: `Right ${subjectType}`
        });
        setIsDropdownOpen(false);
        setIsDiscarded(false);
      };

      const onUndoAssessment = () => {
        onSelectChange({
          value: AssessmentOptions.NoUserAssessment,
          label: `Wrong ${subjectType}?`
        });
        setIsDiscarded(false);
      };

      return (
        <S.DropdownButton>
          <button
            type="button"
            className="verify-button"
            onClick={() => onButtonClick()}
          >
            {" "}
            {buttonIcon}{" "}
          </button>{" "}
          {isDiscarded &&
            userAssessment !== AssessmentOptions.NoUserAssessment && (
              <S.DiscardedCover>
                <S.DiscardedIcon>
                  {isShowingCorrectView ? <Check /> : <Minus />}
                </S.DiscardedIcon>

                <S.DiscardedCoverHeading level={4}>
                  {renderActionTitle()}
                </S.DiscardedCoverHeading>

                <S.UndoButton
                  onClick={onUndoAssessment}
                  type={ButtonType.Filled}
                  size={ButtonSize.Medium}
                  text="Undo"
                />
              </S.DiscardedCover>
            )}
          {isDropdownOpen &&
            (isPDX && !diagnosticsModeEnabled && !pdxDiagnosticsEnabled ? (
              <S.DiscardModalContainer>
                <S.DiscardModalContainerInnerContainer>
                  <DisabledConfirmingModal
                    onClose={() => setIsDropdownOpen(false)}
                  />
                </S.DiscardModalContainerInnerContainer>
              </S.DiscardModalContainer>
            ) : (
              <S.DiscardModalContainer>
                <S.DiscardModal>
                  <S.CloseButton onClick={onHideDiscardModal}>
                    <X />
                  </S.CloseButton>
                  <WrongPersonSvg />
                  <S.DiscardModalHeading level={5}>
                    {renderActionTitle()}
                  </S.DiscardModalHeading>
                  <p>
                    {isShowingCorrectView
                      ? "You know this relates to your subject."
                      : "You know this has no link to your subject."}
                  </p>

                  <S.DiscardModalActions>
                    <S.CancelButton
                      onClick={onHideDiscardModal}
                      type={ButtonType.Filled}
                      size={ButtonSize.Medium}
                      text="Cancel"
                    />
                    <S.ConfirmButton
                      onClick={isShowingCorrectView ? onConfirm : onDiscard}
                      type={ButtonType.Filled}
                      size={ButtonSize.Medium}
                      text={renderActionTitle()}
                      IconLeading={
                        isShowingCorrectView ? RightButtonIcon : WrongButtonIcon
                      }
                    />
                  </S.DiscardModalActions>
                </S.DiscardModal>
              </S.DiscardModalContainer>
            ))}
          <S.DropdownButtonText
            tabIndex={0}
            onClick={onDropdownClick}
            onKeyDown={onKeyDown}
          >
            {renderActionTitle()}
          </S.DropdownButtonText>
        </S.DropdownButton>
      );
    };

    const renderControl = () => (
      <S.ControlButton
        appearsDisabled={disabled || isPDX}
        className={`verification-selection-control-container ${selectionMadeClass}`}
      >
        {renderDropdown()}
      </S.ControlButton>
    );

    return disabled ? (
      <S.Tooltip tooltip={getDisabledTooltipString()} maxWidth="300px">
        {renderControl()}
      </S.Tooltip>
    ) : (
      <>{renderControl()}</>
    );
  }
);

export default inject("reportStore")(AssessmentSelectionControl);
