import { FC, SVGProps } from "react";

import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { standardColors, grey, blue, green } from "styles/colors";
import { fontSize } from "styles/fonts";

import { ButtonSize } from "./types";

/** ClassNameOverrides */

const buttonIcons = css``;

/** Styled components */

const FilledButton = styled.button<{
  size: ButtonSize;
  IconLeading?: FC<SVGProps<SVGSVGElement>>;
  IconTrailing?: FC<SVGProps<SVGSVGElement>>;
  selected?: boolean;
}>`
  --icon-width-height: ${props => {
    switch (props.size) {
      case ButtonSize.Small:
        return "0.75rem";
      default:
        return "1rem";
    }
  }};

  all: unset;
  cursor: pointer;

  padding: ${props => {
    let paddingLeftOffset = 0;
    let paddingRightOffset = 0;

    if (props.size === ButtonSize.Small) {
      if (props.IconLeading) {
        paddingLeftOffset = 0.125;
      }
      if (props.IconTrailing) {
        paddingRightOffset = 0.125;
      }
    } else if (props.size === ButtonSize.Medium) {
      if (props.IconLeading) {
        paddingLeftOffset = 0.25;
      }
      if (props.IconTrailing) {
        paddingRightOffset = 0.25;
      }
    } else {
      if (props.IconLeading) {
        paddingLeftOffset = 0.5;
      }
      if (props.IconTrailing) {
        paddingRightOffset = 0.5;
      }
    }

    switch (props.size) {
      case ButtonSize.ExtraSmall:
        return "0.2rem 0.5rem";
      case ButtonSize.Small:
        return `0.375rem ${0.75 - paddingRightOffset}rem 0.375rem ${
          0.75 - paddingLeftOffset
        }rem`;
      case ButtonSize.Medium:
        return `0.5rem ${1 - paddingRightOffset}rem 0.5rem ${
          1 - paddingLeftOffset
        }rem`;
      case ButtonSize.Large:
      default:
        return `0.75rem ${1.5 - paddingRightOffset}rem 0.75rem ${
          1.5 - paddingLeftOffset
        }rem`;
    }
  }};

  font-size: ${props => {
    switch (props.size) {
      case ButtonSize.ExtraSmall:
        return "0.7rem";
      case ButtonSize.Small:
        return fontSize.xs;
      case ButtonSize.Medium:
        return fontSize.sm;
      case ButtonSize.Large:
      default:
        return fontSize.md;
    }
  }};

  gap: ${props => {
    switch (props.size) {
      case ButtonSize.Large:
        return "0.375rem";
      default:
        return "0.25rem";
    }
  }};

  border-radius: 100px;

  background-color: ${props =>
    props.selected ? blue.xapienDark : standardColors.white};

  transition: background-color 0.3s;
  color: ${props => (props.selected ? standardColors.white : blue.xapienDark)};
  display: flex;
  align-items: center;
  justify-content: center;

  .${buttonIcons} {
    width: var(--icon-width-height);
    height: var(--icon-width-height);
    path {
      stroke: ${props =>
        props.selected ? standardColors.white : blue.xapienDark};
    }
  }

  line-height: ${props => {
    switch (props.size) {
      case ButtonSize.ExtraSmall:
        return "1rem";
      case ButtonSize.Small:
        return "1rem";
      case ButtonSize.Medium:
        return "1.125rem";
      case ButtonSize.Large:
      default:
        return "1.5rem";
    }
  }};

  &:disabled {
    color: ${grey.ghost};
    background-color: ${grey.mid};
    pointer-events: none;

    .${buttonIcons} {
      path {
        stroke: ${grey.ghost};
      }
    }
  }

  &:hover,
  &:focus {
    background-color: ${grey.dark};
    color: ${standardColors.white};

    .${buttonIcons} {
      path {
        stroke: ${standardColors.white};
      }
    }
  }

  &:active {
    background-color: ${grey.ghost};
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

const FilledLightButton = styled(FilledButton)`
  background-color: ${green.xapienGreen};
  color: ${standardColors.white};

  .${buttonIcons} {
    path {
      stroke: ${standardColors.white};
    }
  }

  &:not([data-selected="true"]) {
    &:hover,
    &:focus {
      background-color: ${grey.dark};
      color: ${standardColors.white};
      .${buttonIcons} {
        path {
          stroke: ${standardColors.white};
        }
      }
    }
  }

  &:active {
    background-color: ${grey.ghost};
  }

  &:disabled {
    background-color: ${grey.mid};
    color: ${grey.ghost};

    .${buttonIcons} {
      path {
        stroke: ${grey.ghost};
      }
    }
  }
`;

const OutlinedButton = styled(FilledButton)`
  background-color: ${props => {
    if (props.disabled) {
      return "transparent";
    }
    if (props.selected) {
      return standardColors.white;
    }
    return 0;
  }};

  border: ${props => (props.size === ButtonSize.Large ? "2px" : "1px")} solid
    ${standardColors.white};

  color: ${props => (props.selected ? blue.xapienDark : standardColors.white)};

  .${buttonIcons} {
    path {
      stroke: ${props =>
        props.selected ? blue.xapienDark : standardColors.white};
    }
  }

  &:disabled {
    color: ${props =>
      props.size !== ButtonSize.Large ? grey.ghost : grey.mid};
    border-color: ${grey.mid};
    background-color: ${props =>
      props.size !== ButtonSize.Large ? grey.mid : "transparent"};

    .${buttonIcons} {
      path {
        stroke: ${props =>
          props.size !== ButtonSize.Large ? grey.ghost : grey.mid};
      }
    }
  }

  &:not([data-selected="true"]) {
    &:hover,
    &:focus {
      background-color: ${grey.dark};
      color: ${standardColors.white};
      .${buttonIcons} {
        path {
          stroke: ${standardColors.white};
        }
      }
    }
  }

  &:active {
    background-color: ${grey.ghost};
  }
`;

const OutlinedLightButton = styled(FilledButton)`
  background-color: ${props => {
    if (props.disabled) {
      return "transparent";
    }
    if (props.selected) {
      return green.xapienGreen;
    }
    return 0;
  }};

  border: ${props => (props.size === ButtonSize.Large ? "2px" : "1px")} solid
    ${green.xapienGreen};

  color: ${green.xapienGreen};

  .${buttonIcons} {
    path {
      stroke: ${green.xapienGreen};
    }
  }

  &:disabled {
    color: ${props =>
      props.size !== ButtonSize.Large ? grey.ghost : grey.mid};
    border-color: ${props =>
      props.size !== ButtonSize.Large ? grey.ghost : grey.mid};

    .${buttonIcons} {
      path {
        stroke: ${props =>
          props.size !== ButtonSize.Large ? grey.ghost : grey.mid};
      }
    }
  }

  &:not([data-selected="true"]) {
    &:hover,
    &:focus {
      background-color: ${green.xapienGreen};
      color: ${standardColors.white};
      .${buttonIcons} {
        path {
          stroke: ${standardColors.white};
        }
      }
    }
  }

  &:active {
    background-color: ${grey.ghost};
  }
`;

const TextButton = styled(FilledButton)`
  border-radius: 0;
  line-height: 1.5rem;
  background-color: ${props =>
    props.selected ? standardColors.white : "transparent"};
  color: ${props => (props.selected ? blue.xapienDark : standardColors.white)};

  .${buttonIcons} {
    path {
      stroke: ${props =>
        props.selected ? blue.xapienDark : standardColors.white};
    }
  }

  &:hover,
  &:focus {
    background-color: ${grey.dark};
    color: ${standardColors.white};

    .${buttonIcons} {
      path {
        stroke: ${standardColors.white};
      }
    }
  }

  &:active {
    background-color: ${grey.mid};
  }

  &:disabled {
    background-color: ${grey.mid};
    color: ${grey.ghost};

    .${buttonIcons} {
      path {
        stroke: ${grey.ghost};
      }
    }
  }
`;

const S = {
  FilledButton,
  FilledLightButton,
  OutlinedButton,
  OutlinedLightButton,
  TextButton
};

export const classNameOverrides = { buttonIcons };

export default S;
