import React from "react";

import ScreeningInfographic from "components/molecules/InfoGraphic/ScreeningInfoGraphic";
import { personReportSectionTitles } from "util/reportSectionTitles";
import { SCREENING_FILTERS, SCREENING_LIST_TYPES } from "util/screeningUtils";
import { REPORT_TYPES } from "util/reportTypes";
import RiskSummaryInfoGraphic from "components/molecules/RiskSummaryInfoGraphic";
import { RiskSummaryTopic } from "api/insights";
import {
  getConfirmedExplainerRichText,
  getForReviewExplainerRichText
} from "components/molecules/InfoGraphic/ScreeningInfoGraphic/utils";

const ThemedScreeningInfographics = ({
  screeningData,
  reportSectionRefs,
  reportType,
  CustomInfographic
}) => {
  const confirmedSanctionsData = screeningData?.sanctions?.confirmed;
  const unconfirmedSanctionsData = screeningData?.sanctions?.unconfirmed;
  const confirmedWatchlistsData = screeningData?.watchlists?.confirmed;
  const unconfirmedWatchlistsData = screeningData?.watchlists?.unconfirmed;
  const confirmedPepsData = screeningData?.peps?.confirmed;
  const unconfirmedPepsData = screeningData?.peps?.unconfirmed;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const calculateScreeningCounts = screeningData => {
    return screeningData?.reduce((total, entity) => {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (total += entity?.listings?.length ?? 0);
    }, 0);
  };

  const confirmedSanctionsCount =
    calculateScreeningCounts(confirmedSanctionsData) ??
    screeningData?.sanctions?.confirmedCount; // Backwards compat
  const confirmedWatchlistsCount =
    calculateScreeningCounts(confirmedWatchlistsData) ??
    screeningData?.watchlists?.confirmedCount; // Backwards compat
  const confirmedPepsCount =
    calculateScreeningCounts(confirmedPepsData) ??
    screeningData?.peps?.confirmedCount; // Backwards compat
  const unconfirmedSanctionsCount = calculateScreeningCounts(
    unconfirmedSanctionsData
  );
  const unconfirmedWatchlistsCount = calculateScreeningCounts(
    unconfirmedWatchlistsData
  );
  const unconfirmedPepsCount = calculateScreeningCounts(unconfirmedPepsData);

  if (reportType === REPORT_TYPES.legalEntity) {
    const getSummaryText = (listType, confirmed, unconfirmed) => {
      const confirmedText =
        confirmed > 0 ? getConfirmedExplainerRichText(listType, confirmed) : "";

      const unconfirmedText =
        unconfirmed > 0
          ? getForReviewExplainerRichText(
              listType,
              unconfirmed,
              reportType,
              false
            )
          : "";

      const text =
        confirmedText || unconfirmedText
          ? `${confirmedText} ${unconfirmedText}`
          : null;

      if (text) {
        return text;
      }

      const getEntityTypeString = () => {
        switch (reportType) {
          case REPORT_TYPES.person:
            return "people";
          case REPORT_TYPES.organisation:
            return "organisations";
          case REPORT_TYPES.legalEntity:
            return "legal entities";
          default:
            return reportType;
        }
      };

      return `Xapien has not located any ${getEntityTypeString()} that have matched or bear a resemblance to your subject.`;
    };

    const getUnidentifiedText = unconfirmed => {
      if (!unconfirmed) {
        return null;
      }

      return `+${unconfirmed} to review`;
    };

    return (
      <>
        <RiskSummaryInfoGraphic
          topic={RiskSummaryTopic.SANCTIONS}
          summary={getSummaryText(
            SCREENING_LIST_TYPES.sanctions,
            confirmedSanctionsCount,
            unconfirmedSanctionsCount
          )}
          disclaimer={false}
          unidentifiedText={getUnidentifiedText(unconfirmedSanctionsCount)}
          hideRisk={confirmedSanctionsCount < 1}
        />
        <RiskSummaryInfoGraphic
          topic={RiskSummaryTopic.WATCHLISTS}
          summary={getSummaryText(
            SCREENING_LIST_TYPES.watchlists,
            confirmedWatchlistsCount,
            unconfirmedWatchlistsCount
          )}
          disclaimer={false}
          unidentifiedText={getUnidentifiedText(unconfirmedWatchlistsCount)}
          hideRisk={confirmedWatchlistsCount < 1}
        />
        {reportType === REPORT_TYPES.person && (
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.PEPS}
            summary={getSummaryText(
              SCREENING_LIST_TYPES.peps,
              confirmedPepsCount,
              unconfirmedPepsCount
            )}
            disclaimer={false}
            unidentifiedText={getUnidentifiedText(unconfirmedPepsCount)}
            hideRisk={confirmedPepsCount < 1}
          />
        )}
      </>
    );
  }

  return (
    <>
      <ScreeningInfographic
        confirmedScreeningData={confirmedSanctionsData}
        confirmedScreeningCount={confirmedSanctionsCount}
        unconfirmedScreeningCount={unconfirmedSanctionsCount}
        listType={SCREENING_LIST_TYPES.sanctions}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        listLabel={SCREENING_FILTERS.sanctions.label}
      />
      <ScreeningInfographic
        confirmedScreeningData={confirmedWatchlistsData}
        confirmedScreeningCount={confirmedWatchlistsCount}
        unconfirmedScreeningCount={unconfirmedWatchlistsCount}
        listType={SCREENING_LIST_TYPES.watchlists}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        listLabel={SCREENING_FILTERS.watchLists.label}
      />
      {reportType === REPORT_TYPES.person ? (
        <ScreeningInfographic
          confirmedScreeningData={confirmedPepsData}
          confirmedScreeningCount={confirmedPepsCount}
          unconfirmedScreeningCount={unconfirmedPepsCount}
          listType={SCREENING_LIST_TYPES.peps}
          entityType={reportType}
          sectionToJumpToRef={
            reportSectionRefs &&
            reportSectionRefs[personReportSectionTitles.SCREENING]
          }
          listLabel={SCREENING_FILTERS.peps.label}
        />
      ) : (
        // Organisation reports share the same State owned companies infographic for both PDX, and non PDX reports.
        // So this is passed through at the level of the OrganisationOverview component.
        CustomInfographic
      )}
    </>
  );
};

export default ThemedScreeningInfographics;
