import React, { useState, useMemo, useContext, useEffect } from "react";
import Lottie from "lottie-react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { UserVerificationContext } from "util/hooks/useUserVerification";

import {
  AssessmentOptions,
  convertNewToOld
} from "pages/report/AssessmentOptions";
import ActionFooter from "components/molecules/ActionFooter";
import { ReactComponent as SuccessIcon } from "img/icons/tick-yellow.svg";
import { ReactComponent as FailureIcon } from "img/icons/exclamation-mark-yellow.svg";

import loadingAnimation from "./loading.json";

const RegenerateReportBar = observer(
  ({
    reportStore,
    userAssessmentStore,
    enquiryStore,
    initialHasSaveSucceeded
  }) => {
    const [assessments, setAssessments, , setAssessmentCount] = useContext(
      UserVerificationContext
    );
    const [hasPrimaryActionBeenClicked, setHasPrimaryActionBeenClicked] =
      useState(false);
    const [canRegenerate, setCanRegenerate] = useState(false);
    const [hasSaveSucceeded, setHasSaveSucceeded] = useState(
      initialHasSaveSucceeded
    );

    const navigate = useNavigate();

    const {
      setIsReportSaving,
      isReportSaving,
      report,
      setIsReportRegenerationOpen,
      isUserChangesFooterOpen,
      setIsUserChangesFooterOpen,
      saveRiskMods,
      clearRemovedRiskCategories
    } = reportStore;

    const { currentEnquiryId } = enquiryStore;

    const clearAll = () => {
      setAssessmentCount(0);
      setAssessments(new Map());

      clearRemovedRiskCategories();
      setIsUserChangesFooterOpen(false);
    };

    const regenerateRequested = async () => {
      if (!hasPrimaryActionBeenClicked) {
        setHasPrimaryActionBeenClicked(true);
        const assessmentsToSend =
          assessments &&
          new Map(
            Array.from(assessments).map(kv => [
              kv[0],
              typeof kv[1] === "string" ? convertNewToOld(kv[1]) : kv[1]
            ])
          );

        await userAssessmentStore.applyAssessments(
          currentEnquiryId,
          assessmentsToSend
        );

        clearAll();
        setHasPrimaryActionBeenClicked(false);

        navigate(`/report/preparing/${currentEnquiryId}`);
      }
      setHasSaveSucceeded(null);
    };

    const onSaveChanges = async shouldCloseFooter => {
      setIsReportSaving(true);
      const result = await saveRiskMods(currentEnquiryId);

      if (result.hasErrored) {
        setHasSaveSucceeded(false);
        console.error(result.error);
      } else {
        setHasSaveSucceeded(true);

        // Create a delay to inform the user that the save was
        // successful before moving onto the next action.
        setTimeout(() => {
          if (shouldCloseFooter) {
            setHasSaveSucceeded(null);
            setIsUserChangesFooterOpen(false);
          }
        }, 5000);
      }

      setTimeout(() => {
        setIsReportSaving(false);
      }, 5000);
      setHasPrimaryActionBeenClicked(false);

      return result;
    };

    const isRegenerationFooterOpen = useMemo(() => {
      if (assessments.size === 0) {
        return false;
      }
      return Array.from(assessments.values())?.some(
        value => value !== AssessmentOptions.NoUserAssessment && value !== 0
      );
    }, [assessments]);

    useEffect(() => {
      setCanRegenerate(isRegenerationFooterOpen);
      setIsReportRegenerationOpen(isRegenerationFooterOpen);
    }, [
      assessments,
      isRegenerationFooterOpen,
      report,
      setIsReportRegenerationOpen
    ]);

    const getSavingContent = () => {
      return {
        title: "Saving report...",
        bodyText: "This will only take a few moments.",
        hideActions: true,
        icon: <Lottie animationData={loadingAnimation} />
      };
    };

    const getSaveSucceededContent = () => {
      return {
        title: "Changes saved",
        bodyText: "Your changes have been saved",
        hideActions: true
      };
    };

    const getSaveFailedContent = () => {
      return {
        title: "Unable to save changes",
        bodyText: (
          <>
            We were unable to save your changes.
            <br />
            Please try again.
          </>
        ),
        icon: <FailureIcon />,
        primaryActionString: "Save changes",
        secondaryActionString: "Undo my changes",
        onPrimaryActionClick: () => {
          onSaveChanges(false);
        },
        onSecondaryActionClick: () => {
          setHasSaveSucceeded(null);
          clearRemovedRiskCategories();
        },
        hasPrimaryActionBeenClicked
      };
    };

    const getSaveBarContent = () => {
      if (isReportSaving) {
        return getSavingContent();
      }
      if (hasSaveSucceeded) {
        return getSaveSucceededContent();
      }
      if (hasSaveSucceeded === false) {
        return getSaveFailedContent();
      }

      return {
        title: "Confirm changes",
        bodyText: (
          <>
            When you are done making changes, click &apos;Confirm changes&apos;.
            Your input helps better identify your subject, ensuring Xapien
            generates a <span>more accurate report.</span>
          </>
        ),

        primaryActionString: "Confirm changes",
        secondaryActionString: "Undo my changes",
        onPrimaryActionClick: () => {
          onSaveChanges(true);
        },
        onSecondaryActionClick: () => {
          clearRemovedRiskCategories();
        },
        hasPrimaryActionBeenClicked
      };
    };

    const getRegenAndSaveBarContent = ({ shouldSave }) => {
      if (isReportSaving) {
        return getSavingContent();
      }

      if (hasSaveSucceeded) {
        return {
          ...getSaveSucceededContent(),
          bodyText: "Your changes have been saved. Regenerating report.",
          icon: <SuccessIcon />
        };
      }

      if (hasSaveSucceeded === false) {
        return getSaveFailedContent();
      }
      return {
        title: shouldSave ? "Confirm changes" : "Regenerate the report",
        bodyText: shouldSave ? (
          <>
            When you are done making changes, click &apos;Confirm changes&apos;.
            Your input helps better identify your subject, ensuring Xapien
            generates a <span>more accurate report.</span>
          </>
        ) : (
          <>
            Your input helps better identify your subject, ensuring Xapien
            generates a <span>more accurate report.</span> When you are done
            making changes, you can regenerate your report, which will take{" "}
            <span>5–10 minutes</span> and will not count towards your report
            credits.
          </>
        ),
        primaryActionString: shouldSave ? "Confirm changes" : "Regenerate",
        secondaryActionString: "Undo changes",
        onPrimaryActionClick: async () => {
          let hasSaveFailed = false;
          let result;
          if (shouldSave) {
            result = await onSaveChanges();
            if (result?.hasErrored) {
              hasSaveFailed = true;
            }
          }

          if (!hasSaveFailed) {
            regenerateRequested();
          }
        },
        onSecondaryActionClick: clearAll,
        hasPrimaryActionBeenClicked
      };
    };

    const renderRegenerateReportBar = ({ shouldSave }) => {
      return <ActionFooter {...getRegenAndSaveBarContent({ shouldSave })} />;
    };

    const renderSaveBar = () => {
      return <ActionFooter {...getSaveBarContent()} />;
    };

    const renderBar = () => {
      if (canRegenerate && isUserChangesFooterOpen) {
        return renderRegenerateReportBar({ shouldSave: true });
      }

      if (isUserChangesFooterOpen) {
        return renderSaveBar();
      }

      if (canRegenerate) {
        return renderRegenerateReportBar({ shouldSave: false });
      }

      return null;
    };

    return <AnimatePresence>{renderBar()}</AnimatePresence>;
  }
);

export default RegenerateReportBar;
