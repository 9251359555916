import React, {
  useReducer,
  useCallback,
  useMemo,
  useEffect,
  ReactNode
} from "react";
import dayjs from "dayjs";

import InsightReportsApi from "api/insight-reports";

import { useLocation } from "react-router-dom";
import { useSubjectName } from "util/hooks/useSubjectName";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import useUserSettings from "util/hooks/useUserSettings";
import useViewerMode from "util/hooks/useViewerMode";

import {
  InsightReportActions,
  useInsightReport
} from "util/hooks/useInsightReport";
import { isPDX } from "static-config";
import {
  InsightsAmaAction,
  InsightsAmaActions,
  InsightsAmaMessageStatus,
  InsightsAmaStatus
} from "./types";

import {
  InsightsAmaContext,
  insightsAmaReducer,
  initialState
} from "./context";

export const InsightsAmaContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const { dispatch: insightReportDispatch } = useInsightReport();
  const {
    state: { userDetails }
  } = useUserSettings();
  const { isViewerModeEnabled } = useViewerMode();

  const user = useMemo(
    () => ({
      id: userDetails.userId,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName
    }),
    [userDetails]
  );

  const subjectName = useSubjectName();
  const enquiryId = useEnquiryId();
  const [state, insightsAmaDispatch] = useReducer(
    insightsAmaReducer,
    initialState
  );
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const dispatch = useCallback((action: InsightsAmaAction) => {
    insightsAmaDispatch(action);
  }, []);

  const askQuestion = useCallback(
    (question: string, sectionId: string) => {
      dispatch({
        type: InsightsAmaActions.askQuestion,
        question,
        user,
        sectionId
      });
    },
    [dispatch, user]
  );

  const getMessages = useCallback(
    (sectionId: string) =>
      state.messages.filter(
        message =>
          message.sectionId === sectionId &&
          message.status !== InsightsAmaMessageStatus.saving &&
          message.status !== InsightsAmaMessageStatus.saved
      ),
    [state.messages]
  );

  const getSuggestedQuestions = useCallback(() => {}, []);

  const providerValue = useMemo(
    () => ({
      state,
      dispatch,
      askQuestion,
      getSuggestedQuestions,
      getMessages
    }),
    [state, dispatch, askQuestion, getSuggestedQuestions, getMessages]
  );

  useEffect(() => {
    if (
      state.status === InsightsAmaStatus.askingQuestion &&
      state.questionToAsk &&
      subjectName
    ) {
      const shareToken = params.get("token");

      const api = new InsightReportsApi();

      api
        .askSourcedQuestion({
          enquiryId,
          subjectName,
          question: state.questionToAsk.query,
          shareToken
        })
        .then(({ status, response }) => {
          if (!status || !response || !state.questionToAsk?.messageId) {
            dispatch({ type: InsightsAmaActions.errorAskingQuestion });
            return;
          }

          const userName = user.firstName.length
            ? `${user.firstName} ${user.lastName}`
            : "You";

          dispatch({
            type: InsightsAmaActions.questionAnswered,
            messageId: state.questionToAsk?.messageId,
            answer: {
              ...response,
              created_by: userName,
              date_created: dayjs(new Date()).toISOString()
            }
          });
        })
        .catch(() => {
          dispatch({ type: InsightsAmaActions.errorAskingQuestion });
        });
    }
  }, [
    state.status,
    state.questionToAsk,
    dispatch,
    enquiryId,
    params,
    subjectName,
    user
  ]);

  useEffect(() => {
    if (state.status !== InsightsAmaStatus.savingMessage) return;
    if (!state.messageToSave?.answer?.id) return;

    if (state.messageToSave?.sectionId) {
      insightReportDispatch({
        type: InsightReportActions.addToReportSection,
        sectionId: state.messageToSave.sectionId,
        subsection: state.messageToSave.answer
      });
    }

    // We don't want to save the answers to the report if we're in view-only
    // or in ProjectDX
    if (isViewerModeEnabled || isPDX) {
      return;
    }

    const api = new InsightReportsApi();

    api
      .addToSection({
        reportId: enquiryId,
        answerId: state.messageToSave.answer.id,
        sectionId: state.messageToSave.sectionId
      })
      .then(response => {
        if (response.status) {
          dispatch({
            type: InsightsAmaActions.messageSaved
          });
        } else {
          dispatch({ type: InsightsAmaActions.errorSavingMessage });
        }
      });
  }, [
    state.status,
    state.messageToSave,
    dispatch,
    enquiryId,
    insightReportDispatch,
    isViewerModeEnabled
  ]);

  return (
    <InsightsAmaContext.Provider value={providerValue}>
      {children}
    </InsightsAmaContext.Provider>
  );
};
