import React, { memo, useEffect, useState, useRef, useContext } from "react";

import isEqual from "lodash.isequal";

import ErrorBoundary from "util/ErrorBoundary";
import { CardText } from "pages/report/cards/CardText";
import { CardFooter } from "pages/report/cards/CardFooter";
import { CardHeader } from "pages/report/cards/CardHeader";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import TagsContainer from "pages/report/sections/TagsContainer";
import TagInspectionPanel from "pages/report/sections/WebAndMedia/TagInspectionPanel";
import Assertable from "util/Assertable";
import exactSourceUrlAssertionTemplate from "util/Assertable/util/exactSourceUrlAssertionTemplate";
import { StyledCardText } from "pages/report/cards/StyledCardText";
import SourceLink from "components/atoms/SourceLink";
import { transformWebAndMediaSourceTagsForInspector } from "components/organisms/WithInspector/util";
import { isPDX } from "static-config";

import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import S from "./styles";

const errorCard = props => {
  return (
    <div className={`di-source-card  ${props.isInSnapshot && "media-card"}`}>
      Sorry, there was an error displaying this card
    </div>
  );
};

const SourceCardInnerPanel = memo(props => {
  const {
    originalLanguage,
    subHeading,
    text,
    hide,
    detectedLanguage,
    image,
    url,
    heading,
    readingTime,
    publicationDate,
    provider,
    publicationDateFull,
    resizeHeight,
    supportDetails
  } = props;

  const { enabled: isDiagnosticsMode } = useContext(DiagnosticsModeContext);

  const contradictionStrength =
    supportDetails && Math.floor(supportDetails.contradictionStrength * 100);
  const supportStrength =
    supportDetails && Math.floor(supportDetails.supportStrength * 100);

  return (
    <S.SourceCardInnerPanel hide={hide} hasSubHeading={!!subHeading}>
      <CardHeader
        {...{
          image,
          url,
          heading,
          readingTime,
          detectedLanguage,
          publicationDate,
          provider,
          originalLanguage,
          publicationDateFull
        }}
        {...{ originalLanguage, detectedLanguage }}
      />
      {!isPDX && (subHeading || text) ? ( // for copyright reasons, DowJones don't want us to show article snippets
        <>
          {subHeading ? (
            <S.Subheading>
              <CardText text={subHeading} />
            </S.Subheading>
          ) : (
            ""
          )}
          <S.CardMainText
            className="di-card-main-text"
            heightOffset={resizeHeight}
          >
            {!subHeading && <hr />}
            <CardText text={text} />
          </S.CardMainText>
          {supportDetails && isDiagnosticsMode && (
            <S.SupportDetails>
              ~{contradictionStrength}% Contradiction strength
              <br />~{supportStrength}% Source strength
            </S.SupportDetails>
          )}
        </>
      ) : undefined}
    </S.SourceCardInnerPanel>
  );
});

// inner component helps with render optimisation problems caused by forward ref
const SourceCardInner = memo(props => {
  const {
    isDisregarded,
    sourceId,
    detectedLanguage,
    translatedVersion,
    tags,
    setEmphasis,
    expand,
    contract,
    stackCount,
    showAssessment,
    goldStandardAssessment,
    userAssessment,
    isInSnapshot,
    activeRiskCategoryFilters = [],
    activeRiskDirectionality,
    url,
    heading,
    provider,
    filterOutIndirectTags = false,
    tagColor,
    paginationComponent,
    hideTags = false,
    resizeHeight
  } = props;

  const originalLanguage = detectedLanguage;
  const translatedToLanguage =
    translatedVersion && translatedVersion.detectedLanguage;
  const [showTranslated, setShowTranslated] = usePrintableReportState(
    `source-card-${sourceId}-show-translated`,
    translatedVersion &&
      translatedToLanguage &&
      originalLanguage &&
      translatedToLanguage.toLowerCase() !== originalLanguage.toLowerCase()
  );

  const [selectedTag, setSelectedTag] = useState();

  const [prevActiveRiskCategoryFilters, setPrevActiveRiskCategoryFilters] =
    useState();

  const isTagInFilters = (tag, filters) => {
    return filters?.some(activeFilter => {
      // First, check for a direct match on filter label
      const category = tag.category?.label;
      const matchResult =
        activeFilter[0]?.toLowerCase() === category?.toLowerCase();

      if (matchResult) {
        return true;
      }

      // else, find a match in the hierarchy
      // If the active filter is going as far as tier 1, then...
      if (activeFilter.length === 1) {
        // ...we just need to compare this tier with the first
        // item in the tag's hierarchy.
        return tag.category.hierarchy[0] === activeFilter[0];
      }

      // Otherwise, if the active filter has gone as far as
      // tier 2, then...
      // ...we can compare the filter and hierarchy as strings
      return tag.category.hierarchy.toString() === activeFilter.toString();
    });
  };

  /** Compute the tag that should be auto-selected and sort the tags for render  */

  // When the active filter changes, we want to auto-select
  // the first tag corresponding to that filter so that the
  // snippet displays automatically.
  const tagsThatAppearInFilter = [];
  const tagsThatDoNotAppearInFilter = [];

  const transformedTags = transformWebAndMediaSourceTagsForInspector({
    tags: tags || [],
    filterOutIndirectTags
  });

  let autoSelectedFilter;

  transformedTags.forEach(tag => {
    const isTagFiltered = isTagInFilters(tag, activeRiskCategoryFilters);

    // If the tag appears in the filter and we have not yet
    // picked the tag that will be auto-selected,then...
    if (
      isTagFiltered &&
      !autoSelectedFilter &&
      tag.category?.riskDirection === activeRiskDirectionality
    ) {
      autoSelectedFilter = tag;
    }

    if (isTagFiltered) {
      tagsThatAppearInFilter.push(tag);
    } else {
      tagsThatDoNotAppearInFilter.push(tag);
    }
  });

  // Only update the selected tag state when the currently active category filters
  // change – this is what drives the auto-selection behaviour.
  if (
    prevActiveRiskCategoryFilters === undefined ||
    !isEqual(prevActiveRiskCategoryFilters, activeRiskCategoryFilters)
  ) {
    setSelectedTag(autoSelectedFilter);
    setPrevActiveRiskCategoryFilters(activeRiskCategoryFilters);
  }

  const closeTagInspectionPanel = () => setSelectedTag(undefined);

  const sourceLinkPopoverContainerRef = useRef();

  const [anchorComponent, setAnchorComponent] = useState();

  useEffect(() => {
    setAnchorComponent(sourceLinkPopoverContainerRef.current);
  }, []);

  const showInspectorPanel = selectedTag && !isPDX;
  const showRiskTags =
    !hideTags &&
    (tagsThatAppearInFilter.length > 0 ||
      tagsThatDoNotAppearInFilter.length > 0);

  return (
    <>
      <div className="di-source-card-inner-panel">
        <div className="di-source-card-inner-panel-switcher">
          <S.SourceLinkPopoverContainer ref={sourceLinkPopoverContainerRef} />
          {showInspectorPanel ? (
            <TagInspectionPanel
              {...{ sourceId, originalLanguage }}
              sourceProvider={provider}
              tag={selectedTag}
              onClose={closeTagInspectionPanel}
              heading={
                <div>
                  <SourceLink
                    truncateLink={false}
                    href={
                      translatedVersion
                        ? `https://translate.google.com/translate?u=${encodeURIComponent(
                            url.fullUrl
                          )}`
                        : url.fullUrl
                    }
                    popoverPortalAnchorElement={anchorComponent}
                    renderPopoverWithPortal
                  >
                    <StyledCardText>
                      {translatedVersion && showTranslated
                        ? translatedVersion.heading
                        : heading}
                    </StyledCardText>
                  </SourceLink>
                </div>
              }
            />
          ) : (
            ""
          )}
          {translatedVersion && !showInspectorPanel && showTranslated ? (
            <SourceCardInnerPanel
              {...translatedVersion}
              {...{ originalLanguage }}
              resizeHeight={resizeHeight}
            />
          ) : (
            <SourceCardInnerPanel
              {...props}
              resizeHeight={resizeHeight}
              hide={showInspectorPanel}
            />
          )}
        </div>
        {showRiskTags && (
          <TagsContainer
            {...{
              setSelectedTag,
              tags: [...tagsThatAppearInFilter, ...tagsThatDoNotAppearInFilter],
              selectedTag,
              sourceId,
              tagColor
            }}
            selectDisabled={isPDX}
          />
        )}

        {translatedVersion && showTranslated ? (
          <S.TranslatedLabel>
            <S.GoogleLogo />
          </S.TranslatedLabel>
        ) : (
          originalLanguage && (
            <S.TranslatedLabel>{originalLanguage}</S.TranslatedLabel>
          )
        )}
      </div>
      <ErrorBoundary FallbackComponent={errorCard}>
        <CardFooter
          {...{
            isDisregarded,
            showTranslated,
            setShowTranslated,
            originalLanguage,
            translatedToLanguage,
            setEmphasis,
            expand,
            contract,
            stackCount,
            showAssessment,
            sourceId,
            goldStandardAssessment,
            userAssessment,
            isInSnapshot,
            paginationComponent
          }}
        />
      </ErrorBoundary>
    </>
  );
});

const MediaSourceCard = React.forwardRef((props, ref) => {
  try {
    const { emphasis, disabled, show, isInSnapshot, style, className } = props;
    return (
      <ErrorBoundary FallbackComponent={errorCard}>
        <Assertable
          template={exactSourceUrlAssertionTemplate(
            props.url.fullUrl,
            "Relevant"
          )}
        >
          <div
            style={{ display: show ? "" : "none", ...style }}
            ref={ref}
            className={`di-source-card report-section-content-card${
              emphasis ? " di-source-card-emphasis" : ""
            }${disabled ? " di-source-card-disabled" : ""} ${
              isInSnapshot && "media-card"
            } ${className}`}
          >
            <SourceCardInner {...{ ...props, show: undefined }} />
          </div>
        </Assertable>
      </ErrorBoundary>
    );
  } catch (e) {
    console.error("Error rendering source card", e);
    return (
      <div
        className={`di-source-card report-section-content-card ${
          props.isInSnapshot && "media-card"
        }`}
      >
        Could not display this source due to an error
      </div>
    );
  }
});

export default MediaSourceCard;
