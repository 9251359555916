import React, { useState, CSSProperties, useContext } from "react";

import { isPDX } from "static-config";

import { inject } from "mobx-react";

import { RadioButton } from "components/atoms/RadioButton";
import Popover from "components/atoms/Popover";
import ConfirmationForm from "components/molecules/ConfirmationForm";
import useViewerMode from "util/hooks/useViewerMode";
import ReportStore from "state/ReportStore";
import usePdxDiagnosticsMode from "util/hooks/usePdxDiagnosticsMode";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import DisabledConfirmingModal from "components/molecules/DisabledConfirmingModal";

import S from "./styles";

type Confidence = "confirmed" | "unconfirmed" | "discarded";

interface ConfidenceConfig {
  type: Confidence;
  defaultExplainer: string;
  userOptionExplainer: string;
  undoExplainer?: string;
  regenExplainer?: string;
}

interface ConfidenceStates {
  confirmed: ConfidenceConfig;
  unconfirmed: ConfidenceConfig;
  discarded: ConfidenceConfig;
}

interface Props {
  currentSetConfidence: Confidence;
  originalSetConfidence: Confidence;
  confidenceHasBeenSetOnRegen?: boolean;
  onConfidenceChange: (
    newConfidence: string,
    originalSetConfidence: string,
    itemIds: string[] | string
  ) => void;
  itemIds: string[] | string;
  disabled?: boolean;
  disabledTooltipText?: string;
  reportStore: ReportStore;
  className?: string;
  style?: CSSProperties;
}

const CONFIDENCE_STATES: ConfidenceStates = {
  confirmed: {
    type: "confirmed",
    defaultExplainer:
      "Xapien is confident this is linked to your subject either directly or indirectly (e.g. through a person).",
    userOptionExplainer: "I know this is linked to my subject.",
    undoExplainer:
      "You have confirmed this is linked to your subject either directly or indirectly (e.g. through a person).",
    regenExplainer:
      "You have previously confirmed this is linked to your subject."
  },
  discarded: {
    type: "discarded",
    defaultExplainer: "Xapien is confident this has no link to your subject.",
    userOptionExplainer: "I know this has no link to my subject.",
    undoExplainer: "You have confirmed this has no link to your subject.",
    regenExplainer:
      "You have previously confirmed this has no link to your subject."
  },
  unconfirmed: {
    type: "unconfirmed",
    defaultExplainer:
      "Xapien is not confident this is linked to your subject either directly or indirectly (e.g. through a person).",
    userOptionExplainer: "I'm not sure if this is linked to my subject."
  }
};

const ConfidenceMenu = ({
  currentSetConfidence,
  originalSetConfidence,
  confidenceHasBeenSetOnRegen,
  onConfidenceChange = () => {},
  itemIds,
  disabled = false,
  disabledTooltipText,
  reportStore,
  className,
  style
}: Props) => {
  const [isConfidenceMenuOpen, setIsConfidenceMenuOpen] = useState(false);
  const [radioSelectionConfidence, setRadioSelectionConfidence] = useState();

  const { isViewerModeEnabled } = useViewerMode();
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const { enabled: pdxDiagnosticsEnabled } = usePdxDiagnosticsMode();

  // We can use the absence of the item ids as an indicator on whether
  // the report is old (therefore we do not have the correct data to perform confidence switching and regeneration)
  const isItemIdPresent =
    (Array.isArray(itemIds) && !itemIds?.length) || itemIds;

  const shouldMenuBeDisabled =
    disabled || !isItemIdPresent || isViewerModeEnabled;

  const hasChangedFromOriginalConfidence =
    currentSetConfidence !== originalSetConfidence;

  const onApplyConfidence = (newConfidence: Confidence) => {
    setIsConfidenceMenuOpen(false);
    onConfidenceChange(newConfidence, originalSetConfidence, itemIds);
    setRadioSelectionConfidence(undefined);
  };

  const getBannerText = () => {
    if (hasChangedFromOriginalConfidence) {
      return CONFIDENCE_STATES[currentSetConfidence]?.undoExplainer;
    }
    if (confidenceHasBeenSetOnRegen) {
      return CONFIDENCE_STATES[currentSetConfidence]?.regenExplainer;
    }
    return CONFIDENCE_STATES[currentSetConfidence]?.defaultExplainer;
  };

  const renderConfidenceExplainer = () => {
    return (
      <>
        <S.ConfidenceType>
          {currentSetConfidence}
          <S.UserSetInfoText>
            {hasChangedFromOriginalConfidence || confidenceHasBeenSetOnRegen
              ? " by user: "
              : ": "}
          </S.UserSetInfoText>
        </S.ConfidenceType>{" "}
        {getBannerText()}
      </>
    );
  };

  const renderTooltipText = () => {
    if (shouldMenuBeDisabled && disabledTooltipText) {
      return disabledTooltipText;
    }

    if (isViewerModeEnabled) {
      return "Confirm/discard is disabled in viewer mode.";
    }

    if (!isItemIdPresent) {
      return "Confirm/discard is disabled because this report was generated using an old verison of our software; please re-run the report";
    }

    return (
      <S.TooltipExplainerText>
        {renderConfidenceExplainer()}
      </S.TooltipExplainerText>
    );
  };

  const renderModalContent = () => {
    let body;
    let newConfidence: Confidence;
    let promptText;
    let promptSubtext;

    if (hasChangedFromOriginalConfidence) {
      body = (
        <div>
          <S.PromptText>
            Would you like to <strong>undo</strong> this?
          </S.PromptText>
          <S.PromptSubtext>{`It will revert back to ${
            originalSetConfidence.slice(0, 1).toUpperCase() +
            originalSetConfidence.slice(1)
          }.`}</S.PromptSubtext>
        </div>
      );
    } else if (
      currentSetConfidence === CONFIDENCE_STATES.confirmed.type ||
      currentSetConfidence === CONFIDENCE_STATES.discarded.type
    ) {
      if (currentSetConfidence === CONFIDENCE_STATES.confirmed.type) {
        newConfidence = CONFIDENCE_STATES.discarded.type;
        promptText = "discard";
        promptSubtext = "You know this has no link to your subject.";
      } else {
        newConfidence = CONFIDENCE_STATES.confirmed.type;
        promptText = "confirm";
        promptSubtext = "You know this is linked to your subject.";
      }

      body = (
        <div>
          <S.PromptText>
            Would you like to <strong>{promptText}</strong> this?
          </S.PromptText>
          <S.PromptSubtext>{promptSubtext}</S.PromptSubtext>
        </div>
      );
    } else {
      body = (
        <>
          <S.SelectionPrompt>Change to...</S.SelectionPrompt>
          {Object.values(CONFIDENCE_STATES).map(value => {
            if (value.type !== CONFIDENCE_STATES.unconfirmed.type) {
              return (
                <S.ConfidenceRadioInputContainer
                  key={value.type}
                  onClick={() => {
                    newConfidence = value.type;
                    setRadioSelectionConfidence(value.type);
                  }}
                >
                  <RadioButton
                    value={value.type}
                    checked={radioSelectionConfidence === value.type}
                    onChange={() => {
                      newConfidence = value.type;
                      setRadioSelectionConfidence(value.type);
                    }}
                  />
                  <S.ConfidenceLabel htmlFor={value.type}>
                    {value.type}
                  </S.ConfidenceLabel>
                  : {value.userOptionExplainer}
                </S.ConfidenceRadioInputContainer>
              );
            }
            return null;
          })}
        </>
      );
    }

    if (isPDX && !diagnosticsModeEnabled && !pdxDiagnosticsEnabled) {
      return (
        <S.DisabledConfirmingModalContainer>
          <DisabledConfirmingModal
            onClose={() => setIsConfidenceMenuOpen(false)}
          />
        </S.DisabledConfirmingModalContainer>
      );
    }

    return (
      <ConfirmationForm
        header="Tell Xapien what you know"
        subHeader={renderConfidenceExplainer()}
        body={body}
        footerTertiaryNode={
          !hasChangedFromOriginalConfidence && "You can undo this later."
        }
        secondaryActionString="Cancel"
        primaryActionString={
          <S.PrimaryActionString>
            {hasChangedFromOriginalConfidence
              ? "Undo"
              : promptText ?? "Apply..."}
          </S.PrimaryActionString>
        }
        togglePopover={() => setIsConfidenceMenuOpen(false)}
        onPrimaryActionClick={() =>
          onApplyConfidence(
            hasChangedFromOriginalConfidence
              ? originalSetConfidence
              : newConfidence ?? radioSelectionConfidence
          )
        }
        reportStore={reportStore}
        isPrimaryActionDisabled={
          currentSetConfidence === CONFIDENCE_STATES.unconfirmed.type &&
          !radioSelectionConfidence
        }
      />
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={className} style={style} onClick={e => e.stopPropagation()}>
      <Popover
        alignment="bottom-end"
        hideArrow
        borderRadius={12}
        trigger="click"
        isOpenOverride={isConfidenceMenuOpen}
        onRequestClose={() => setIsConfidenceMenuOpen(false)}
        content={renderModalContent()}
        position="fixed"
      >
        <Popover
          position="fixed"
          borderRadius={12}
          disabled={isConfidenceMenuOpen}
          content={<S.TooltipContent>{renderTooltipText()}</S.TooltipContent>}
          maxWidth="300px"
        >
          <S.DropdownToggle
            disabled={shouldMenuBeDisabled}
            onClick={() => setIsConfidenceMenuOpen(prev => !prev)}
            className={
              hasChangedFromOriginalConfidence || confidenceHasBeenSetOnRegen
                ? "hasChangedFromOriginalConfidence"
                : ""
            }
          >
            <S.MenuButtonIcon
              aria-label="Change confidence"
              className={
                hasChangedFromOriginalConfidence || confidenceHasBeenSetOnRegen
                  ? "hasChangedFromOriginalConfidence"
                  : ""
              }
            />
          </S.DropdownToggle>
        </Popover>
      </Popover>
    </div>
  );
};

export default inject("reportStore")(ConfidenceMenu);
