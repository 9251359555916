import React, { CSSProperties, ReactNode } from "react";

import { ButtonType, ButtonSize } from "components/atoms/ButtonNew/types";

import S from "./styles";

interface Props {
  title: string;
  bodyText: string;
  style?: CSSProperties;
  onSecondaryActionClick: () => void;
  onPrimaryActionClick: () => void;
  primaryActionString: string;
  secondaryActionString: string;
  hideActions?: boolean;
  icon?: ReactNode;
}

const ActionFooter = ({
  title,
  bodyText,
  style,
  onSecondaryActionClick,
  onPrimaryActionClick,
  primaryActionString,
  secondaryActionString,
  hideActions,
  icon
}: Props) => {
  const variants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 0
    },
    exit: {
      y: 300
    }
  };

  return (
    <S.Footer
      // Classname here is important to prevent click events on the footer from closing the inspector
      className="regen-bar"
      style={style}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
        // Delay footer enter/exit animation (reduces animation lag as there is a lot going on during this action)
        delay: 0.3
      }}
      variants={variants}
      initial="offscreen"
      animate="onscreen"
      exit="exit"
    >
      {icon && <S.IconContainer>{icon}</S.IconContainer>}
      <S.Title>{title}</S.Title>
      <S.Body>{bodyText}</S.Body>
      {!hideActions && (
        <S.Actions>
          <S.PrimaryAction
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            onClick={onPrimaryActionClick}
            text={primaryActionString}
          />
          <S.SecondaryAction
            type={ButtonType.Text}
            size={ButtonSize.Medium}
            onClick={onSecondaryActionClick}
            text={secondaryActionString}
          />
        </S.Actions>
      )}
    </S.Footer>
  );
};

export default ActionFooter;
