import { REPORT_TYPES } from "util/reportTypes";
import { reportStore } from "state/Store";

const useReportType = () => {
  const report = reportStore.report as any;
  const subjectType = report?.subjectType;

  const isLegalEntity =
    subjectType === REPORT_TYPES.organisation &&
    report?.contexts[0].item.includes("graph.sayari.com");

  if (isLegalEntity) {
    return REPORT_TYPES.legalEntity;
  }

  return subjectType;
};

export default useReportType;
