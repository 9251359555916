import React, { ReactNode } from "react";

import { observer } from "mobx-react";

import { ButtonKind } from "components/atoms/Button/types";

import { red, standardColors } from "styles/colors";
import ReportStore from "state/ReportStore";

import S from "./styles";

interface Props {
  header?: ReactNode;
  subHeader?: ReactNode;
  body: ReactNode;
  secondaryActionString: ReactNode;
  primaryActionString: ReactNode;
  togglePopover: () => void;
  isPrimaryActionDanger?: boolean;
  onPrimaryActionClick: () => void;
  isPrimaryActionDisabled?: boolean;
  footerTertiaryNode?: ReactNode;
  reportStore: ReportStore;
}

const ConfirmationForm = observer(
  ({
    header,
    subHeader,
    body,
    secondaryActionString,
    primaryActionString,
    togglePopover,
    isPrimaryActionDanger,
    onPrimaryActionClick,
    isPrimaryActionDisabled,
    footerTertiaryNode,
    reportStore
  }: Props) => {
    const isReportSaving = reportStore?.isReportSaving;

    const renderPrimaryAction = () => {
      return (
        <S.Tooltip
          content={<S.TooltipContent>Save in progress...</S.TooltipContent>}
          position="fixed"
          disableHideOnClip
          disabled={!isReportSaving}
        >
          <S.ConfirmButton
            kind={ButtonKind.primary}
            onClick={onPrimaryActionClick}
            color={
              isPrimaryActionDanger ? red.directRisk : standardColors.white
            }
            disabled={isReportSaving || isPrimaryActionDisabled}
          >
            {primaryActionString}
          </S.ConfirmButton>
        </S.Tooltip>
      );
    };

    return (
      <S.DropdownContainer>
        <S.DropdownHeader>{header}</S.DropdownHeader>
        {subHeader && <S.DropdownSubtext>{subHeader}</S.DropdownSubtext>}
        <>
          <S.DropdownBody>{body}</S.DropdownBody>
          <S.DropdownFooter>
            <S.FooterInfoText>{footerTertiaryNode}</S.FooterInfoText>
            <div>
              <S.CancelButton
                kind={ButtonKind.secondary}
                onClick={togglePopover}
              >
                {secondaryActionString}
              </S.CancelButton>
              {renderPrimaryAction()}
            </div>
          </S.DropdownFooter>
        </>
      </S.DropdownContainer>
    );
  }
);

export default ConfirmationForm;
