import React, { createContext, useContext, useMemo } from "react";
import { Version, useVersion } from "./version";

export class Feature {
  constructor(name, alwaysOnVersion) {
    this.name = name;
    this.alwaysOnVersion = alwaysOnVersion;
    Object.freeze(this);
  }
}

const buildFeature = (name, major, minor, patch) =>
  new Feature(
    name,
    major !== undefined ? new Version(major, minor, patch) : undefined
  );
// names have to match backend definitions
// when feature switches deleted on backend,
// assuming they are in effect,
// the alwaysOnVersion should be setup here

export const Features = Object.freeze({
  FilterTangentialSources: buildFeature("FilterTangentialSources", 1, 8, 0),
  NotProcessedSection: buildFeature("NotProcessedSection", 1, 8, 0),
  SnapshotSection: buildFeature("SnapshotSection", 1, 9, 0)
});

const ActiveFeaturesContext = createContext(null);

export const ActiveFeatuesContextProvider = props => {
  const { children, featureNames } = props;
  return (
    <ActiveFeaturesContext.Provider value={featureNames || []}>
      {children}
    </ActiveFeaturesContext.Provider>
  );
};

export const useFeatureEnabled = feature => {
  const version = useVersion();
  const activeFeatureNames = useContext(ActiveFeaturesContext);
  return useMemo(() => {
    if (
      feature.alwaysOnVersion &&
      version &&
      version.isAfterOrEqualTo(feature.alwaysOnVersion)
    ) {
      return true;
    } else if (
      activeFeatureNames.some(
        n => n.toLowerCase() === feature.name.toLowerCase()
      )
    ) {
      return true;
    } else {
      return false;
    }
  }, [feature, activeFeatureNames, version]);
};
