import type { InsightReport } from "api/insight-reports/types";

import type {
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection
} from "api/insights";

export enum InsightReportActions {
  // Adding new section
  addNewSection = "addNewSection",
  addNewSectionSuccess = "addNewSectionSuccess",
  errorAddingNewSection = "errorAddingNewSection",

  // Adding to report
  addToReportSection = "addToReportSection",
  errorAddingToSection = "errorAddingToSection",

  // Fetching report
  fetchReport = "fetchReport",
  errorFetchingReport = "errorFetchingReport",

  // Fetching sources
  errorFetchingSources = "errorFetchingSources",
  updatingFetchingSources = "updatingFetchingSources",

  // Moving sections
  errorMovingSubSection = "errorMovingSubSection",
  moveSubSection = "moveSubSection",
  moveSubsectionSuccess = "moveSubsectionSuccess",

  // Removing sections
  errorRemovingReportSection = "errorRemovingReportSection",
  removeReportElement = "removeReportElement",
  removeReportSection = "removeReportSection",
  removeReportSubSection = "removeReportSubSection",
  removeReportSectionSuccess = "removeReportSectionSuccess",

  // Reordering sections
  errorReOrderingSubSection = "errorReOrderingSubSection",
  reorderSubSections = "reorderSubSections",
  reorderSubSectionsSuccess = "reorderSubSectionsSuccess",

  // Welcome disclaimer
  hideWelcomeDisclaimer = "hideWelcomeDisclaimer",
  showWelcomeDisclaimer = "showWelcomeDisclaimer",

  // Sourcing explainer
  hideSourcingExplainer = "hideSourcingExplainer",

  // Remove report
  removeReport = "removeReport",

  // Navigating sections
  showPreviousSectionSlug = "showPreviousSectionSlug",
  updateActiveSectionSlug = "updateActiveSectionSlug",

  // Update report
  updateReport = "updateReport",
  updateReportSubSection = "updateReportSubSection",
  regenerateReport = "regenerateReport",

  // timeline actions
  fetchTimeline = "fetchTimeline",
  errorFetchingTimeline = "errorFetchingTimeline",
  updateTimeline = "updateTimeline",

  // Editing section titles
  startEditingTitle = "startEditingTitle",
  stopEditingTitle = "stopEditingTitle",
  resetEditingTitle = "resetEditingTitle",
  updateTitle = "updateTitle"
}

export enum InsightReportStatus {
  addingNewSection = "addingNewSection",
  editingTitle = "editingTitle",
  errorAddingNewSection = "errorAddingNewSection",
  errorAddingToSection = "errorAddingToSection",
  errorFetchingReport = "errorFetchingReport",
  errorFetchingSources = "errorFetchingSources",
  errorMovingSubSection = "errorMovingSubSection",
  errorRemovingReportSection = "errorRemovingReportSection",
  errorReOrderingSubSection = "errorReOrderingSubSection",
  fetching = "fetching",
  fetchingSources = "fetchingSources",
  idle = "idle",
  movingSubSection = "movingSubSection",
  movingSubsectionSuccess = "movingSubsectionSuccess",
  regeneratingReport = "regeneratingReport",
  removed = "removed",
  removingReportElement = "removingReportElement",
  removingReportSection = "removingReportSection",
  removingReportSubSection = "removingReportSubSection",
  removingReportSectionSuccess = "removingReportSectionSuccess",
  reorderingSubSections = "reorderSubSections",
  reorderingSubSectionsSuccess = "reorderingSubSectionsSuccess",
  savingTitle = "savingTitle"
}

interface AddNewSectionSuccessAction {
  type: InsightReportActions.addNewSectionSuccess;
}

interface AddNewSectionAction {
  type: InsightReportActions.addNewSection;
  section: RawSection;
}

interface ErrorAddingNewSectionAction {
  type: InsightReportActions.errorAddingNewSection;
}

interface ResetEditingTitleAction {
  type: InsightReportActions.resetEditingTitle;
}

interface StartEditingTitleAction {
  type: InsightReportActions.startEditingTitle;
  sectionId: string;
  subsectionId?: string;
  elementId?: string;
}

interface StopEditingTitleAction {
  type: InsightReportActions.stopEditingTitle;
}

interface UpdateTitleAction {
  type: InsightReportActions.updateTitle;
  title: string;
}

interface AddToReportSectionAction {
  type: InsightReportActions.addToReportSection;
  sectionId: string;
  subsection: RawSubsection;
}

interface ErrorAddingToSectionAction {
  type: InsightReportActions.errorAddingToSection;
}

interface ErrorFetchingReportAction {
  type: InsightReportActions.errorFetchingReport;
  errorMessage?: string;
}

interface ErrorFetchingSourcesAction {
  type: InsightReportActions.errorFetchingSources;
  errorFetchingSourcesId: string;
}

interface ErrorMovingSubSectionAction {
  type: InsightReportActions.errorMovingSubSection;
}

interface ErrorRemovingReportSectionAction {
  type: InsightReportActions.errorRemovingReportSection;
}

interface ErrorReOrderingSubSectionAction {
  type: InsightReportActions.errorReOrderingSubSection;
}

interface FetchReportAction {
  type: InsightReportActions.fetchReport;
}

interface HideWelcomeDisclaimerAction {
  type: InsightReportActions.hideWelcomeDisclaimer;
}

interface HideSourcingExplainerAction {
  type: InsightReportActions.hideSourcingExplainer;
}

interface MoveSubSectionAction {
  type: InsightReportActions.moveSubSection;
  sectionId: string;
  subSectionId: string;
  destinationSectionId: string;
}

interface MoveSubsectionSuccessAction {
  type: InsightReportActions.moveSubsectionSuccess;
}

interface RegenerateReportAction {
  type: InsightReportActions.regenerateReport;
}

interface RemoveReportAction {
  type: InsightReportActions.removeReport;
}

interface RemoveReportElementAction {
  type: InsightReportActions.removeReportElement;
  elementId: string;
  subSectionId: string;
  sectionId: string;
}

interface RemoveReportSectionAction {
  type: InsightReportActions.removeReportSection;
  sectionId: string;
}

interface RemoveReportSubSectionAction {
  type: InsightReportActions.removeReportSubSection;
  subSectionId: string;
  sectionId: string;
}

interface RemoveReportSectionSuccessAction {
  type: InsightReportActions.removeReportSectionSuccess;
}

interface ReorderSubSectionAction {
  type: InsightReportActions.reorderSubSections;
  sectionId: string;
  subsectionId: string;
  direction: string;
}

interface ReorderSubSectionsSuccessAction {
  type: InsightReportActions.reorderSubSectionsSuccess;
}

interface ShowPreviousSectionSlugAction {
  type: InsightReportActions.showPreviousSectionSlug;
}

interface ShowWelcomeDisclaimerAction {
  type: InsightReportActions.showWelcomeDisclaimer;
}

interface UpdateActiveSectionSlugAction {
  type: InsightReportActions.updateActiveSectionSlug;
  slug: string;
}

interface UpdateReportAction {
  type: InsightReportActions.updateReport;
  rawReport: RawReport;
  reportId: string;
  activeSectionSlug?: string;
}

interface UpdateReportSubSectionAction {
  type: InsightReportActions.updateReportSubSection;
  sectionId: string;
  subsectionId: string;
  subSection: RawSubsection;
}

interface UpdatingFetchingSourcesAction {
  type: InsightReportActions.updatingFetchingSources;
  nodeId: string;
}

export type InsightReportAction =
  | AddNewSectionSuccessAction
  | AddNewSectionAction
  | ErrorAddingNewSectionAction
  | AddToReportSectionAction
  | ErrorAddingToSectionAction
  | ErrorFetchingReportAction
  | ErrorFetchingSourcesAction
  | ErrorMovingSubSectionAction
  | ErrorRemovingReportSectionAction
  | ErrorReOrderingSubSectionAction
  | FetchReportAction
  | HideWelcomeDisclaimerAction
  | HideSourcingExplainerAction
  | MoveSubSectionAction
  | MoveSubsectionSuccessAction
  | RegenerateReportAction
  | RemoveReportAction
  | RemoveReportElementAction
  | RemoveReportSectionAction
  | RemoveReportSubSectionAction
  | RemoveReportSectionSuccessAction
  | ReorderSubSectionAction
  | ReorderSubSectionsSuccessAction
  | ShowPreviousSectionSlugAction
  | ShowWelcomeDisclaimerAction
  | UpdateActiveSectionSlugAction
  | UpdateReportAction
  | UpdateReportSubSectionAction
  | UpdatingFetchingSourcesAction
  | StartEditingTitleAction
  | StopEditingTitleAction
  | UpdateTitleAction
  | ResetEditingTitleAction;

export interface InsightReportState {
  activeSectionSlug: string;
  contentToAddToSection?: RawSubsection;
  currentSectionId?: string;
  currentSubSectionId?: string;
  destinationSectionId?: string;
  elementIdToRemove?: string;
  errorMessage?: string;
  fetchedSourcesNodeIds: string[];
  errorFetchingSourcesId?: string;
  isShowingWelcomeDisclaimer: boolean;
  isShowingSourcingExplainer: boolean;
  previousSectionSlug?: string;
  rawReport?: RawReport;
  reorderingSectionId?: string;
  reorderingSubsectionId?: string;
  reorderingSubsectionDirection?: string;
  report?: InsightReport;
  sectionIdToAddTo?: string;
  sectionIdToRemove?: string;
  status: InsightReportStatus;
  subsectionIdToRemove?: string;
  updatedTitleSectionId?: string;
  updatedTitleSubsectionId?: string;
  updatedTitleElementId?: string;
  updatedTitle?: string;
}
