import React, { FC, useState, useEffect } from "react";
import UpcReportsApi from "api/upc-reports";
import { useEnquiryId } from "util/hooks/useEnquiryId";

import BasicDetails, { BasicDetail } from "components/molecules/BasicDetails";

import ErrorScreen from "components/molecules/ErrorScreen";
import { useLocation } from "react-router-dom";

interface Props {
  title: string;
}

const ERROR_TEXT =
  // eslint-disable-next-line max-len
  "We were unable to narrow down the group or parent company from the corporate data available. We recommend that you go to the Ownership Preview section.";

const UPCDetails: FC<Props> = ({ title }) => {
  const enquiryId = useEnquiryId();
  const [upcData, setUpcData] = useState<BasicDetail[][] | undefined>(
    undefined
  );
  const { search } = useLocation();

  useEffect(() => {
    const api = new UpcReportsApi();

    const params = new URLSearchParams(search);
    const shareToken = params.get("token");

    api
      .getDetails({ enquiryId, shareToken })
      .then(({ response }) => setUpcData(response))
      .catch(() => setUpcData(undefined));
  }, [enquiryId, search]);

  if (!upcData) {
    return <ErrorScreen compact errorText={ERROR_TEXT} />;
  }

  return <BasicDetails title={title} details={upcData} />;
};

export default UPCDetails;
