import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import config from "config";
import { TimelineEvent } from "api/insights";
import { GetTimelineErrorCodes } from "api/insight-reports";
import NoEvents from "components/organisms/InsightReport/TimelineSection/NoEvents";
import LoadingTimeline from "components/organisms/InsightReport/TimelineSection/LoadingTimeline";
import { useInsightsTimeline } from "util/hooks/useInsightsTimeline";
import { InsightsTimelineStatus } from "util/hooks/useInsightsTimeline/types";
import { useSubjectName } from "util/hooks/useSubjectName";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";

import {
  ALIGNMENT_OPTIONS,
  MEDIUM_NODE_SOURCE_COUNT,
  SMALL_NODE_SOURCE_COUNT
} from "./constants";

import S from "./styles";
import ErrorSection from "../ErrorSection";
import DeleteTimeLineEventModal from "./DeleteTimeLineEventModal";

export interface TimelineSectionProps {
  timeline?: TimelineEvent[];
}

// Used to calculate the the size of the circle node for each event.
// Returns a number which is the size of the node in pixels.
const getNodeSize = (event: TimelineEvent) => {
  const count = event.sources.length;

  if (count < SMALL_NODE_SOURCE_COUNT) {
    return 15; // Small node size.
  }

  if (count < MEDIUM_NODE_SOURCE_COUNT) {
    return 21; // Medium node size.
  }

  return 29; // Large node size.
};

const TimelineEventItem = ({
  status,
  timeline,
  eventIdToDelete,
  event,
  index,
  handleOpenDeleteModal
}: {
  status: InsightsTimelineStatus;
  index: number;
  event: TimelineEvent;
  timeline: TimelineEvent[];
  eventIdToDelete?: string;
  handleOpenDeleteModal: (event: TimelineEvent) => void;
}) => {
  const [isOnTheWrongSide, setIsOnTheWrongSide] = useState(false);
  const [position, _setPosition] = useState(index);
  const previousEvent = timeline[position - 1];

  const isDeletingSuccess =
    status === InsightsTimelineStatus.deletingEventSuccess;

  const isOnTheRight = position % 2 === 1;

  const nodeSize = getNodeSize(event);

  useEffect(() => {
    if (
      isDeletingSuccess &&
      previousEvent &&
      previousEvent.id === eventIdToDelete
    ) {
      setIsOnTheWrongSide(true);
    }
  }, [isDeletingSuccess, eventIdToDelete, previousEvent]);

  return (
    <S.ListItem
      isOnTheRight={isOnTheRight}
      isOnTheWrongSide={isOnTheWrongSide}
      isDeletingSuccess={isDeletingSuccess}
      nodeSize={nodeSize}
      exit={{ opacity: 0, height: 0 }}
      transition={{
        opacity: { duration: 0.5, delay: 1 },
        height: { duration: 0.5, delay: 1.5 }
      }}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      layout
    >
      <S.TimelineEvent
        event={event}
        alignment={
          isOnTheRight ? ALIGNMENT_OPTIONS.left : ALIGNMENT_OPTIONS.right
        }
        nodeSize={nodeSize}
        isFirst={index === 0}
        handleToggleDeleteModel={() => handleOpenDeleteModal(event)}
      />
    </S.ListItem>
  );
};

const TimelineSection: React.FC<TimelineSectionProps> = ({ timeline }) => {
  const subjectName = useSubjectName();
  const {
    state: { status, errorMessage, eventId: eventIdToDelete }
  } = useInsightsTimeline();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<TimelineEvent>();
  const isPrintMode = usePrintModeEnabled();

  const handleOpenDeleteModal = (event: TimelineEvent) => {
    setEventToDelete(event);
    setIsModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setEventToDelete(undefined);
    setIsModalOpen(false);
  };

  if (
    status === InsightsTimelineStatus.fetching ||
    (status === InsightsTimelineStatus.error &&
      errorMessage === GetTimelineErrorCodes.IN_PROGRESS_ERROR)
  ) {
    return <LoadingTimeline />;
  }

  if (errorMessage === GetTimelineErrorCodes.FAILED_ERROR) {
    return (
      <ErrorSection showRegenerateButton>
        <p>
          We&apos;ve been unable to generate a timeline for this subject.
          <br />
          Please re-run the report or contact{" "}
          <a href={`mailto:${config.supportEmail}`}>Customer Success</a> for
          assistance.
        </p>
      </ErrorSection>
    );
  }

  if (!timeline?.length) {
    return <NoEvents />;
  }

  const firstEvent = timeline[0];
  const lastEvent = timeline[timeline.length - 1];

  return (
    <S.SectionContent isPrintMode={isPrintMode}>
      <S.Heading isPrintMode={isPrintMode}>Media Events Timeline</S.Heading>
      <S.Subheading isPrintMode={isPrintMode}>
        Newsworthy events for {subjectName}, {lastEvent.date!.month_short}{" "}
        {lastEvent.date!.year} - {firstEvent.date!.month_short}{" "}
        {firstEvent.date!.year}
      </S.Subheading>
      <S.HeadingDivider />

      <S.TimelineContainer>
        <S.LineContainer>
          <S.LineTopCover />
          <S.Line />
          <S.LineTerminus />
        </S.LineContainer>
        <div>
          <AnimatePresence>
            {timeline.map((event, index) => {
              const hasBeenDeleted =
                status === InsightsTimelineStatus.deletingEventSuccess &&
                event.id === eventIdToDelete;

              return (
                !hasBeenDeleted && (
                  <TimelineEventItem
                    key={`TimelineSectionListItem-${event.title}`}
                    timeline={timeline}
                    eventIdToDelete={eventIdToDelete}
                    status={status}
                    index={index}
                    event={event}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                  />
                )
              );
            })}
          </AnimatePresence>
        </div>
        <S.ClearingElement />
      </S.TimelineContainer>
      <DeleteTimeLineEventModal
        isOpen={isModalOpen}
        onCloseModal={handleCloseDeleteModal}
        event={eventToDelete}
      />
    </S.SectionContent>
  );
};

export default TimelineSection;
