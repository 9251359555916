import React, { useEffect, useRef } from "react";

import config from "config";
import { Section } from "pages/report/Section";
import { useFeatureEnabled, Features } from "services/features";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import DowJonesDisclaimer from "pages/report/sections/DowJonesDisclaimer";
import XapienDisclaimer from "pages/report/sections/XapienDisclaimer";
import ImageGallery from "pages/report/sections/ImageGallery";
import Profiles from "pages/report/sections/Profiles";
import AwardEducation from "components/organisms/AwardEducation";
import SectionErrorWrapper from "pages/report/SectionErrorWrapper";
import ReportHeader from "pages/report/ReportHeader";
import Footer from "components/atoms/Footer";
import WebAndMediaArticles from "components/organisms/WebAndMediaArticles";
import Locations from "components/organisms/Locations";
import ProfessionalOverview from "components/organisms/ProfessionalOverview";
import AssociatedIndustries from "components/organisms/AssociatedIndustries";
import LocationsFallback from "components/organisms/Locations/LocationsFallback";
import AssociatedIndustriesFallback from "components/organisms/AssociatedIndustries/AssociatedIndustriesFallback";
import ProfessionalOverviewFallback from "components/organisms/ProfessionalOverview/ProfessionalOverviewFallback";
import WebAndMediaArticlesFallback from "components/organisms/WebAndMediaArticles/WebAndMediaArticlesFallback";
import ImageBankFallback from "components/organisms/ImageBank/ImageBankFallback";
import ScreeningFallback from "components/organisms/ScreeningSection/ScreeningFallback";
import Notes from "components/organisms/Notes";
import { useVersion } from "services/version";
import { PROFILE_CARD_TYPE } from "pages/report/sections/Profiles/utils";
import { isReportVersionTooOldForScreening } from "util/screeningUtils";
import DisregardedInformation from "pages/report/sections/WebAndMedia/DisregardedInformation";
import NotProcessedSection from "pages/report/sections/WebAndMedia/NotProcessedSection";
import {
  UNRESOLVABLE_ADDRESS_STRING,
  UNSPECIFIED_ADDRESS_STRING
} from "components/organisms/Locations/utils";
import { doesTenantProhibitImages } from "util/doesTenantProhibitImages";
import { personReportSectionTitles } from "util/reportSectionTitles";
import { InfographicStateContextProvider } from "util/context/InfographicStateContext";
import { UserNotesContextProvider } from "util/hooks/useUserNotes";
import { ENTITY_TYPE } from "pages/define/utils";
import ScreeningSection from "components/organisms/ScreeningSection";

import ThemedScreening from "ThemedScreening";

import ThemedPersonOverview from "ThemedPersonOverview";
import { ReportStoreContextProvider } from "util/hooks/useReports";
import InsightReport from "components/organisms/InsightReport";
import { InsightsSection as OldInsightReport } from "components/organisms/InsightsSection";
import { showInsightReport } from "util/insightReportHelpers";
import { isPDX } from "static-config";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { scrollToTopOfComponent } from "util/common";
import { useLocation, useNavigate } from "react-router-dom";

const Report = props => {
  const {
    report,
    assessments,
    setAssessments,
    setAssessment,
    setCollapsed,
    sectionCollapseStates,
    imageSelection,
    setImageSelection,
    shellContainerRef,
    isReportRegenerationOpen,
    nameOnlySearch,
    contextsWithTypes,
    subjectType
  } = props;

  const haveNotProcessedSection = useFeatureEnabled(
    Features.NotProcessedSection
  );

  // refs for scrolling to components
  const linkRefs = {
    personOverview: useRef(null),
    businessAssociates: useRef(null),
    locations: useRef(null),
    associatedIndustries: useRef(null),
    professionalOverview: useRef(null),
    webAndMedia: useRef(null),
    awards: useRef(null),
    education: useRef(null),
    disregarded: useRef(null),
    notProcessed: useRef(null),
    awardEducation: useRef(null),
    screening: useRef(null),
    imageGallery: useRef(null),
    notes: useRef(null),
    disclaimer: useRef(null)
  };
  const isPrintMode = usePrintModeEnabled();
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash.length < 2) {
      return;
    }

    const ref = linkRefs[hash.substring(1)];
    if (ref) {
      scrollToTopOfComponent(ref, true);
      navigate("#"); // Clear hash so links with the same hash still work.
    }
    // Disable the deps warning as we'll need to add linkRefs but it can't be memoised.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, navigate]);

  // Add state for each section here
  const [personalExpanded, setPersonalExpanded] = usePrintableReportState(
    "Personal Details-section-expanded",
    true
  );
  const [businessAssociateExpanded, setBusinessAssociateExpanded] =
    usePrintableReportState("Business Associates-section-expanded", true);
  const [educationExpanded, setEducationExpanded] = usePrintableReportState(
    "Education Overview-section-expanded",
    true
  );
  const [disregardedExpanded, setDisregardedExpanded] = usePrintableReportState(
    "Disregarded Information-section-expanded",
    false
  );
  const [notProcessedExpanded, setNotProcessedExpanded] =
    usePrintableReportState("Not Processed-section-expanded", false);

  const [screeningExpanded, setScreeningExpanded] = usePrintableReportState(
    "screening-section-expanded",
    true
  );

  const [imageGalleryExpanded, setImageGalleryExpanded] =
    usePrintableReportState("image-gallery-section-expanded", true);

  const [webAndMediaExpanded, setWebAndMediaExpanded] = usePrintableReportState(
    "web-and-media-section-expanded",
    true
  );

  const [locationsExpanded, setLocationsExpanded] = usePrintableReportState(
    "locations-section-expanded",
    true
  );

  const [professionalOverviewExpanded, setProfessionalOverviewExpanded] =
    usePrintableReportState("professional-overview-expanded", true);

  const [associatedIndustriesExpanded, setAssociatedIndustriesExpanded] =
    usePrintableReportState("associated-industries-expanded", true);

  const [notesExpanded, setNotesExpanded] = usePrintableReportState(
    "notes-section-expanded",
    isPrintMode
  );

  const [disclaimerExpanded, setDisclaimerExpanded] = usePrintableReportState(
    "notes-section-expanded",
    true
  );

  const version = useVersion();
  const isVersionTooOldForScreening =
    isReportVersionTooOldForScreening(version);

  const isImageGalleryVisible = !doesTenantProhibitImages();

  const sectionTitles = personReportSectionTitles;

  const hasSourcesAndIsNotPDX = report.reportSourcing && !isPDX;

  // Add object for each section here
  const allSections = [
    {
      title: sectionTitles.PERSONAL_DETAILS,
      linkRef: linkRefs.personOverview,
      expandable: false,
      defaultExpanded: personalExpanded,
      setExpanded: val => setPersonalExpanded(val)
    },
    {
      title: sectionTitles.NOTES,
      linkRef: linkRefs.notes,
      expandable: true,
      defaultExpanded: notesExpanded,
      setExpanded: val => setNotesExpanded(val)
    },
    !isVersionTooOldForScreening && {
      title: sectionTitles.SCREENING,
      linkRef: linkRefs.screening,
      expandable: true,
      defaultExpanded: screeningExpanded,
      setExpanded: val => setScreeningExpanded(val)
    },
    {
      title: sectionTitles.PROFESSIONAL_OVERVIEW,
      linkRef: linkRefs.professionalOverview,
      expandable: true,
      defaultExpanded: professionalOverviewExpanded,
      setExpanded: val => setProfessionalOverviewExpanded(val)
    },
    {
      title: sectionTitles.BUSINESS_ASSOCIATES,
      linkRef: linkRefs.businessAssociates,
      expandable: true,
      defaultExpanded: businessAssociateExpanded,
      itemsToDisplayInitially: 4,
      setExpanded: val => setBusinessAssociateExpanded(val)
    },
    {
      title: sectionTitles.LOCATIONS,
      linkRef: linkRefs.locations,
      expandable: true,
      defaultExpanded: locationsExpanded,
      setExpanded: val => setLocationsExpanded(val)
    },
    {
      title: sectionTitles.ASSOCIATED_INDUSTRIES,
      linkRef: linkRefs.associatedIndustries,
      expandable: true,
      defaultExpanded: associatedIndustriesExpanded,
      setExpanded: val => setAssociatedIndustriesExpanded(val)
    },
    isImageGalleryVisible && {
      title: sectionTitles.IMAGE_GALLERY,
      linkRef: linkRefs.imageGallery,
      expandable: true,
      defaultExpanded: imageGalleryExpanded,
      setExpanded: val => setImageGalleryExpanded(val)
    },
    {
      title: sectionTitles.WEB_AND_MEDIA,
      linkRef: linkRefs.webAndMedia,
      expandable: true,
      defaultExpanded: webAndMediaExpanded,
      setExpanded: val => setWebAndMediaExpanded(val)
    },
    {
      title: sectionTitles.AWARDS_EDUCATION,
      linkRef: linkRefs.awardEducation,
      expandable: true,
      itemsToDisplayInitially: 2,
      defaultExpanded: educationExpanded,
      setExpanded: val => setEducationExpanded(val)
    },
    hasSourcesAndIsNotPDX && {
      title: sectionTitles.DISREGARDED,
      linkRef: linkRefs.disregarded,
      expandable: true,
      defaultExpanded: disregardedExpanded,
      setExpanded: val => setDisregardedExpanded(val)
    },
    haveNotProcessedSection &&
      hasSourcesAndIsNotPDX && {
        title: sectionTitles.NOT_PROCESSED,
        linkRef: linkRefs.notProcessed,
        defaultExpanded: notProcessedExpanded,
        setExpanded: val => setNotProcessedExpanded(val)
      }
  ];

  const renderPersonOverview = () => {
    const sectionRefs = {};
    allSections.forEach(section => {
      sectionRefs[section?.title] = section?.linkRef;
    });

    const professionalRolesMap = {};
    report.professionalOverview?.listOfProfessionalInformation?.forEach(
      professionalRow => {
        professionalRolesMap[professionalRow.id] = professionalRow;
      }
    );

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.personOverview}
        title={sectionTitles.PERSONAL_DETAILS}
        isExpanded
        expandable={false}
      >
        <InfographicStateContextProvider>
          <ReportStoreContextProvider>
            <ThemedPersonOverview
              reportAbstract={{
                ...report.reportAbstract,
                isDeceased: report.insightCentre?.deceasedPattern?.deceasedFlag
              }}
              professionalRolesMap={professionalRolesMap}
              ref={linkRefs.personOverview}
              title={sectionTitles.PERSONAL_DETAILS}
              sectionRefs={sectionRefs}
            />
          </ReportStoreContextProvider>
        </InfographicStateContextProvider>
      </SectionErrorWrapper>
    );
  };

  const renderDisregardedAndNotProcessed = () => {
    const { reportSourcing } = report;
    const newReportSourcing = !!reportSourcing;
    if (!newReportSourcing) {
      return null;
    }
    return (
      <>
        {reportSourcing.nonRelevantSourceGroups.length ? (
          <SectionErrorWrapper
            forwardedRef={linkRefs.disregarded}
            title={sectionTitles.DISREGARDED}
            isExpanded={disregardedExpanded}
            expandable
            setExpanded={val => setDisregardedExpanded(val)}
          >
            <DisregardedInformation
              title={sectionTitles.DISREGARDED}
              sourceCount={reportSourcing.nonRelevantSourceCount}
              sourceGroups={reportSourcing.nonRelevantSourceGroups}
              {...{
                setAssessment,
                setAssessments,
                assessments,
                sectionCollapseStates,
                setCollapsed,
                imageSelection,
                setImageSelection
              }}
              isExpanded={disregardedExpanded}
              setExpanded={val => setDisregardedExpanded(val)}
              ref={linkRefs.disregarded}
            />
          </SectionErrorWrapper>
        ) : null}
        <SectionErrorWrapper
          forwardedRef={linkRefs.notProcessed}
          title={sectionTitles.NOT_PROCESSED}
          isExpanded={notProcessedExpanded}
          expandable
          setExpanded={val => setNotProcessedExpanded(val)}
        >
          <NotProcessedSection
            title={sectionTitles.NOT_PROCESSED}
            sourceCount={reportSourcing.notProcessedSourceCount}
            sourceGroups={reportSourcing.notProcessedSourceGroups}
            {...{
              setAssessment,
              setAssessments,
              assessments,
              sectionCollapseStates,
              setCollapsed,
              imageSelection,
              setImageSelection
            }}
            isExpanded={notProcessedExpanded}
            setExpanded={val => setNotProcessedExpanded(val)}
            ref={linkRefs.notProcessed}
          />
        </SectionErrorWrapper>
      </>
    );
  };

  const renderBusinessAssociate = () => {
    const newBusinessAssociatesSectionFound = !!report.businessAssociates;
    if (newBusinessAssociatesSectionFound) {
      return (
        <SectionErrorWrapper
          forwardedRef={linkRefs.businessAssociates}
          title={sectionTitles.BUSINESS_ASSOCIATES}
          isExpanded={businessAssociateExpanded}
          expandable
          setExpanded={val => setBusinessAssociateExpanded(val)}
        >
          <Profiles
            title={sectionTitles.BUSINESS_ASSOCIATES}
            profileData={report.businessAssociates}
            ref={linkRefs.businessAssociates}
            isExpanded={businessAssociateExpanded}
            setExpanded={val => setBusinessAssociateExpanded(val)}
            nameOnlySearch={nameOnlySearch}
            isReportRegenerationOpen={isReportRegenerationOpen}
            profileType={PROFILE_CARD_TYPE.businessAssociates}
          />
        </SectionErrorWrapper>
      );
    }
    return null;
  };

  const renderLocations = () => {
    const locationsData = report.location?.listOfLocationInformation?.length
      ? report.location.listOfLocationInformation
          .filter(location => {
            if (!location.filterInfo) {
              return false;
            }

            return true;
          })
          .map((location, index) => {
            const filterInfo = { ...location.filterInfo };
            if (!location.filterInfo.city && !location.filterInfo.countryName) {
              filterInfo.countryName = UNRESOLVABLE_ADDRESS_STRING;
              filterInfo.city = UNRESOLVABLE_ADDRESS_STRING;
            }

            if (!location.filterInfo.city && location.filterInfo.countryName) {
              filterInfo.city = UNSPECIFIED_ADDRESS_STRING;
            }

            const streetViewImageId =
              location.locationStreetViewImage?.id ?? index;

            return {
              ...location,
              filterInfo: {
                ...filterInfo
              },
              locationId: location.locationMapImage?.id ?? index,
              locationStreetViewImage: {
                ...location.locationStreetViewImage,
                id: streetViewImageId
              }
            };
          })
      : [];

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.locations}
        title={sectionTitles.LOCATIONS}
        isExpanded={locationsExpanded}
        expandable
        setExpanded={val => setLocationsExpanded(val)}
      >
        <Section
          title={sectionTitles.LOCATIONS}
          ref={linkRefs.locations}
          isExpanded={locationsExpanded}
          setExpanded={val => setLocationsExpanded(val)}
        >
          {locationsData?.length ? (
            <Locations
              locationData={locationsData}
              isReportRegenerationOpen={isReportRegenerationOpen}
            />
          ) : (
            <LocationsFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderWebAndMedia = () => {
    const snapshotData = report.snapshotSection ?? {
      media: {}
    };

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.webAndMedia}
        title={sectionTitles.WEB_AND_MEDIA}
        isExpanded={webAndMediaExpanded}
        expandable
        setExpanded={val => setWebAndMediaExpanded(val)}
      >
        <Section
          ref={linkRefs.webAndMedia}
          title={sectionTitles.WEB_AND_MEDIA}
          isExpanded={webAndMediaExpanded}
          setExpanded={setWebAndMediaExpanded}
        >
          {report.reportSourcing?.relevantSourceGroups?.length ? (
            <ReportStoreContextProvider>
              <WebAndMediaArticles
                media={snapshotData.media}
                relevantSourceCount={report.reportSourcing?.relevantSourceCount}
                isReportRegenerationOpen={isReportRegenerationOpen}
                isSectionARootSection
              />
            </ReportStoreContextProvider>
          ) : (
            <WebAndMediaArticlesFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderAwardEducation = () => {
    const newAwardsEducationSectionFound = !!(
      report.education || report.awards
    );
    if (newAwardsEducationSectionFound) {
      return (
        <SectionErrorWrapper
          forwardedRef={linkRefs.awardEducation}
          title={sectionTitles.AWARDS_EDUCATION}
          isExpanded={educationExpanded}
          expandable
          setExpanded={val => setEducationExpanded(val)}
        >
          <AwardEducation
            title={sectionTitles.AWARDS_EDUCATION}
            awardData={
              report.awards ? report.awards.listOfAwardsInformation : []
            }
            educationData={
              report.education
                ? report.education.listOfEducationInformation
                : []
            }
            ref={linkRefs.awardEducation}
            isExpanded={educationExpanded}
            setExpanded={val => setEducationExpanded(val)}
          />
        </SectionErrorWrapper>
      );
    }
    return null;
  };

  const renderScreening = () => {
    const isScreeningSectionPresent =
      (report.screeningSection || report.dowPersonScreeningSection) &&
      !isVersionTooOldForScreening;

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.screening}
        title={sectionTitles.SCREENING}
        isExpanded={screeningExpanded}
        expandable
        setExpanded={val => setScreeningExpanded(val)}
      >
        <Section
          title={sectionTitles.SCREENING}
          ref={linkRefs.screening}
          isExpanded={screeningExpanded}
          setExpanded={val => setScreeningExpanded(val)}
        >
          {isScreeningSectionPresent ? (
            <>
              {/* Backward compatibility check for older DJ reports still using the old 'screeningSection' field */}
              {!report.dowPersonScreeningSection && report.screeningSection ? (
                <ScreeningSection
                  screeningData={{ default: report.screeningSection }}
                  reportSubjectType={ENTITY_TYPE.Person}
                  hideCAFooter={config.tenant === "projectdx"}
                />
              ) : (
                <ThemedScreening
                  screeningData={{
                    default: report.screeningSection,
                    dowSection: report.dowPersonScreeningSection
                  }}
                  reportSubjectType={ENTITY_TYPE.Person}
                  reportSubjectName={report.reportMetadata.subject}
                  isReportRegenerationOpen={isReportRegenerationOpen}
                />
              )}
            </>
          ) : (
            <ScreeningFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderImageGallery = () => {
    if (isImageGalleryVisible) {
      return (
        <SectionErrorWrapper
          forwardedRef={linkRefs.imageGallery}
          title={sectionTitles.IMAGE_GALLERY}
          isExpanded={imageGalleryExpanded}
          expandable
          setExpanded={val => setImageGalleryExpanded(val)}
        >
          <Section
            ref={linkRefs.imageGallery}
            isExpanded={imageGalleryExpanded}
            setExpanded={val => setImageGalleryExpanded(val)}
            title={sectionTitles.IMAGE_GALLERY}
          >
            {report.snapshotSection?.photoMontage?.length ? (
              <ImageGallery
                photoMontage={report.snapshotSection.photoMontage}
              />
            ) : (
              <ImageBankFallback />
            )}
          </Section>
        </SectionErrorWrapper>
      );
    }
    return null;
  };

  const renderProfessionalOverview = () => {
    const professionalInformation = {
      confirmed:
        report.professionalOverview?.listOfProfessionalInformation ?? [],
      unconfirmed:
        report.professionalOverview?.unconfirmedListOfProfessionalInformation ??
        [],
      discarded:
        report.professionalOverview?.discardedListOfProfessionalInformation ??
        []
    };

    const isProfessionalInformationPresent =
      professionalInformation.confirmed?.length ||
      professionalInformation.unconfirmed?.length ||
      professionalInformation.discarded?.length;

    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.professionalOverview}
        title={sectionTitles.professionalOverview}
        isExpanded={professionalOverviewExpanded}
        expandable
        setExpanded={val => setProfessionalOverviewExpanded(val)}
      >
        <Section
          title={sectionTitles.PROFESSIONAL_OVERVIEW}
          ref={linkRefs.professionalOverview}
          isExpanded={professionalOverviewExpanded}
          setExpanded={val => setProfessionalOverviewExpanded(val)}
          expandable
        >
          {isProfessionalInformationPresent ? (
            <ProfessionalOverview
              originalListOfProfessionalInformation={professionalInformation}
              employmentHistory={
                report?.snapshotSection?.employmentHistory ?? {}
              }
              isReportRegenerationOpen={isReportRegenerationOpen}
              subjectName={report.subject}
            />
          ) : (
            <ProfessionalOverviewFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderAssociatedIndustries = () => {
    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.associatedIndustries}
        title={sectionTitles.ASSOCIATED_INDUSTRIES}
        isExpanded={associatedIndustriesExpanded}
        expandable
        setExpanded={val => setAssociatedIndustriesExpanded(val)}
      >
        <Section
          title={sectionTitles.ASSOCIATED_INDUSTRIES}
          setExpanded={val => setAssociatedIndustriesExpanded(val)}
          isExpanded={associatedIndustriesExpanded}
          ref={linkRefs.associatedIndustries}
        >
          {report.snapshotSection?.employmentHistory?.industryCounts?.length ? (
            <AssociatedIndustries
              employmentHistory={report.snapshotSection.employmentHistory ?? {}}
            />
          ) : (
            <AssociatedIndustriesFallback />
          )}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderNotes = () => {
    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.notes}
        title={sectionTitles.NOTES}
        isExpanded={notesExpanded}
        setExpanded={val => setNotesExpanded(val)}
        expandable
      >
        <UserNotesContextProvider>
          <Notes
            title={sectionTitles.NOTES}
            ref={linkRefs.notes}
            isExpanded={notesExpanded}
            setExpanded={val => setNotesExpanded(val)}
          />
        </UserNotesContextProvider>
      </SectionErrorWrapper>
    );
  };

  const renderDisclaimer = () => {
    return (
      <SectionErrorWrapper
        forwardedRef={linkRefs.disclaimer}
        title={
          isPDX ? sectionTitles.PDXDISCLAIMER : sectionTitles.XAPIENDISCLAIMER
        }
        isExpanded={disclaimerExpanded}
        setExpanded={val => setDisclaimerExpanded(val)}
        expandable
      >
        <Section
          title={
            isPDX ? sectionTitles.PDXDISCLAIMER : sectionTitles.XAPIENDISCLAIMER
          }
          isExpanded={disclaimerExpanded}
          setExpanded={val => setDisclaimerExpanded(val)}
          ref={linkRefs.disclaimer}
        >
          {isPDX ? <DowJonesDisclaimer /> : <XapienDisclaimer />}
        </Section>
      </SectionErrorWrapper>
    );
  };

  const renderReportSections = () => {
    const isShowingInsightReport = report
      ? showInsightReport(report.preparedAtUtc)
      : false;

    return (
      <>
        {renderPersonOverview()}
        {isShowingInsightReport ? <InsightReport /> : <OldInsightReport />}
        {renderNotes()}
        {renderScreening()}
        {renderProfessionalOverview()}
        {renderBusinessAssociate()}
        {renderLocations()}
        {renderAssociatedIndustries()}
        {renderImageGallery()}
        {renderWebAndMedia()}
        {renderAwardEducation()}
        {renderDisregardedAndNotProcessed()}
        {renderDisclaimer()}
      </>
    );
  };

  const renderBody = () => {
    const searchErrorInfos = report.searchErrorInfo || [];
    const systemIssues = report.systemIssues || [];

    return (
      <>
        <ReportHeader
          menuItems={allSections}
          report={report}
          title={report.fullName || report.subject}
          shellContainerRef={shellContainerRef}
          contextsWithTypes={contextsWithTypes}
          subjectType={subjectType}
        />
        <div className="report-inner-container">
          {renderReportSections()}
          <Footer
            systemIssues={systemIssues}
            searchErrorInfos={searchErrorInfos}
          />
        </div>
      </>
    );
  };

  return renderBody();
};

export default Report;
