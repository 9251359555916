import { apm } from "@elastic/apm-rum";
import { IdentityTenantApiService } from "api/portal";

import { Report } from "api/reports";
import {
  buildReportCardTags,
  buildReportScreeningFlags,
  mapReportStateToStatus,
  mapReportType
} from "api/reports/utils";
import { CollectionListView } from "components/molecules/CollectionListControls";
import {
  CollectionInputType,
  CollectionListState,
  CollectionStatus
} from "util/hooks/useCollectionList/types";
import { CollectionSearchState } from "util/hooks/useCollectionSearch/types";

export const ORGANISATION_USER_REPORTS_COLLECTION = "organisation-user-reports";

export default class Reports {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: ORGANISATION_USER_REPORTS_COLLECTION,
          title: "Reports",
          items: [],
          status: CollectionStatus.stale,
          totalItemCount: 0,
          view: CollectionListView.list,
          offset: 0,
          limit: 16,
          order: "desc",
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list
          },
          hidden: false,
          pollingEnabled: true
        }
      ]
    };
  }

  async list({
    id: _id,
    limit: _limit,
    offset: _offset,
    filters: _filters,
    filterByUserId
  }: {
    id: string;
    limit: number;
    offset: number;
    filters?: string[];
    filterByUserId?: string;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    try {
      if (!filterByUserId) {
        return { items: [], totalItemCount: 0 };
      }

      const { reports, total: totalItemCount } =
        await IdentityTenantApiService.getTenantsUsersReports({
          userId: filterByUserId
        });

      if (!reports) return { items: [], totalItemCount: 0 };

      const items = reports
        .filter(Boolean)
        .map(
          ({
            reportId,
            imageUrl,
            displayName,
            groups,
            contexts,
            startTime,
            state,
            sanctions,
            watchLists,
            pepsOrStateOwned,
            rcas,
            sips,
            flags,
            owner,
            project,
            monitoring,
            viewerModeUrl,
            type
          }) => {
            return {
              id: reportId ?? "unknown",
              imageSrc: imageUrl ?? "",
              status: mapReportStateToStatus(state),
              title: displayName ?? "unknown",
              author:
                owner?.firstName || owner?.lastName
                  ? `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`
                  : "unknown",
              authorId: owner?.userId ?? "",
              authorEmail: owner?.email ?? "",
              createdAt: startTime ?? "",
              context: contexts?.join(", ") ?? "unknown",
              projectReference: project?.reference ?? undefined,
              viewerModeUrl: viewerModeUrl ?? undefined,
              permissions: {
                canView: true,
                canEdit: true,
                canDelete: true,
                canViewSharingPreferences: true,
                canEditSharingPreferences: true,
                canViewShareLink: true,
                canGenerateShareLink: true,
                canDeleteShareLink: true,
                canTransferOwnership: true,
                canExport: true,
                canViewAdverseMediaMonitoring: true,
                canEditAdverseMediaMonitoring: true,
                canViewSanctionMonitoring: true,
                canEditSanctionMonitoring: true
              },
              monitoring: {
                adverseMedia: !!monitoring?.adverseMedia,
                sanctions: !!monitoring?.sanctions
              },
              tags: buildReportCardTags(
                filterByUserId,
                owner?.userId!,
                groups!
              ),
              risks: buildReportScreeningFlags({
                sanctions: sanctions ?? 0,
                watchLists: watchLists ?? 0,
                pepsOrStateOwned: pepsOrStateOwned ?? 0,
                flags: flags ?? 0,
                rcas: rcas ?? 0,
                sips: sips ?? 0
              }),
              type: mapReportType(type)
            };
          }
        );

      return { items, totalItemCount: totalItemCount ?? reports.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async search() {
    return { items: [], totalItemCount: 0 };
  }
}
