import React from "react";

import InsightReport from "components/organisms/InsightReport";

import { EnquiryIdContext } from "util/hooks/useEnquiryId";

import S from "./styles";

const InsightReportPage = () => {
  return (
    <EnquiryIdContext.Provider value="123">
      <S.Container>
        <InsightReport />
      </S.Container>
    </EnquiryIdContext.Provider>
  );
};

export default InsightReportPage;
