// @ts-nocheck - due to the types being lost due to framer motion, we can't check types in this file anymore :(
import { styled } from "@linaria/react";
import { motion } from "framer-motion";

import { grey } from "styles/colors";
import {
  fontSize,
  family,
  headingLetterSpacing,
  lineHeight
} from "styles/fonts";

import Event from "components/organisms/InsightReport/TimelineSection/Event";

export interface ListItemProps {
  nodeSize: number;
  isOnTheRight: boolean;
  isOnTheWrongSide: boolean;
  isDeletingSuccess: boolean;
}

export interface SectionContentProps {
  isPrintMode: boolean;
}

export interface HeadingProps {
  isPrintMode: boolean;
}

export interface SubheadingProps {
  isPrintMode: boolean;
}

const Heading = styled.h2<HeadingProps>`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.xl};
  font-weight: inherit;
  letter-spacing: ${headingLetterSpacing.xl};
  line-height: ${lineHeight.md};
  margin: 0;
  margin-bottom: 12px;
  padding: 14px 24px 0;
  padding: ${({ isPrintMode }) => (isPrintMode ? "14px 0 0" : "14px 24px 0")};
  line-height: ${fontSize.xl};
`;

const Subheading = styled.h3<SubheadingProps>`
  font-size: ${fontSize.lg};
  font-family: ${family.interRegular};
  font-weight: inherit;
  letter-spacing: ${headingLetterSpacing.xl};
  line-height: ${lineHeight.md};
  padding: ${({ isPrintMode }) => (isPrintMode ? "0" : "0 24px")};
`;

const HeadingDivider = styled.hr`
  margin: 12px 0 36px 0;
`;

const LineContainer = styled.div`
  position: absolute;
  width: 1px;
  background-color: ${grey.rule};
  height: 100%;
  left: calc(50% - 0.5px);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LineTerminus = styled.div`
  height: 1px;
  width: 10px;
  background-color: ${grey.rule};
`;

// Covers the top of the timeline line so
// it doesn't poke through the top node.
const LineTopCover = styled.div`
  height: 13px;
  width: 1px;
  background-color: ${grey.panel};
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${grey.rule};
`;

const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 24px;

  ul {
    padding: 0;
    margin: 0;
  }
`;

const TimelineEvent = styled(Event)``;

const ListItem = styled<ListItemProps>(motion.div)`
  position: relative;
  clear: both;
  width: 50%;

  margin-left: ${({ isOnTheRight, nodeSize }) =>
    isOnTheRight ? "0" : `calc(50% - ${nodeSize / 2}px)`} !important;

  // Add margin to last event to ensure delete button isn't cover
  // by the fade at the bottom of the XI section.
  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const ClearingElement = styled.div`
  clear: both;
`;

const SectionContent = styled.div<SectionContentProps>`
  width: 100%;
  padding: 10px 0;
  overflow-y: ${({ isPrintMode }) => (isPrintMode ? "visible" : "hide")};
  flex-direction: column;
`;

const S = {
  Heading,
  Subheading,
  HeadingDivider,
  ListItem,
  TimelineContainer,
  LineContainer,
  LineTopCover,
  LineTerminus,
  Line,
  TimelineEvent,
  ClearingElement,
  SectionContent
};

export default S;
