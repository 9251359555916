import { useEffect, useMemo, useCallback, useState } from "react";

import Api from "api/insight-reports";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import { useSubjectName } from "util/hooks/useSubjectName";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useInsightReport } from "util/hooks/useInsightReport";
import { useLocation } from "react-router-dom";

const MAX_SUGGESTIONS = 4;

const useInsightsSuggestions = () => {
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);
  const [suggestedQuestionsIndex, setSuggestedQuestionsIndex] = useState(0);

  const [
    { fetching: loadingSuggestions, error: suggestionsError },
    suggestionsDispatch
  ] = useFetchReducer();

  const {
    state: { rawReport }
  } = useInsightReport();

  const subjectName = useSubjectName();
  const enquiryId = useEnquiryId();
  const location = useLocation();

  const InsightsApi = useMemo(() => new Api(), []);

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const getSuggestions = useCallback(() => {
    if (!subjectName) return;

    if (suggestedQuestionsIndex + MAX_SUGGESTIONS < suggestedQuestions.length) {
      setSuggestedQuestionsIndex(suggestedQuestionsIndex + MAX_SUGGESTIONS);

      return;
    }

    // If the report has pregenerated suggestions, it should loop back to the
    // start if we reach the end of the suggestions.
    if (rawReport?.suggestions) {
      setSuggestedQuestionsIndex(0);

      return;
    }

    setTimeout(() => {
      suggestionsDispatch({ type: RequestActions.SendRequest });

      const shareToken = params.get("token");
      const apiCall = shareToken
        ? InsightsApi.getSuggestedQuestionsWithShareToken({
            id: enquiryId,
            subjectName,
            shareToken
          })
        : InsightsApi.getSuggestedQuestions({ id: enquiryId, subjectName });

      apiCall
        .then(({ status, response }) => {
          if (status) {
            const newSuggestions = response ?? [];
            setSuggestedQuestionsIndex(suggestedQuestions.length);
            setSuggestedQuestions(suggestedQuestions.concat(newSuggestions));
            suggestionsDispatch({ type: RequestActions.SetSuccess });
          } else {
            suggestionsDispatch({
              type: RequestActions.SetError,
              errorMessage: "There was an unknown error"
            });
          }
        })
        .catch(() => {
          suggestionsDispatch({
            type: RequestActions.SetError,
            errorMessage: "There was an unknown error"
          });
        });
    }, 200);
  }, [
    InsightsApi,
    enquiryId,
    subjectName,
    suggestedQuestions,
    setSuggestedQuestions,
    suggestionsDispatch,
    suggestedQuestionsIndex,
    rawReport?.suggestions,
    params
  ]);

  useEffect(() => {
    if (suggestedQuestions.length > 0) {
      return;
    }

    const pregeneratedSuggestions = rawReport?.suggestions ?? [];
    if (pregeneratedSuggestions.length > 0) {
      setSuggestedQuestions(pregeneratedSuggestions);
    } else {
      getSuggestions();
    }
  }, [rawReport?.suggestions, getSuggestions, suggestedQuestions]);

  const questionsToReturn = suggestedQuestions.slice(
    suggestedQuestionsIndex,
    suggestedQuestionsIndex + MAX_SUGGESTIONS
  );

  return {
    suggestedQuestions: questionsToReturn,
    getSuggestions,
    loadingSuggestions,
    suggestionsError
  };
};

export default useInsightsSuggestions;
