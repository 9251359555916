import React, {
  useMemo,
  useCallback,
  useReducer,
  useEffect,
  FC,
  ReactNode
} from "react";
import { useLocation } from "react-router-dom";

import { useEnquiryId } from "util/hooks/useEnquiryId";
import InsightReportsApi, { GetTimelineErrorCodes } from "api/insight-reports";

import { TimelineEvent } from "api/insights";
import type { InsightsTimelineAction } from "./types";
import { InsightsTimelineStatus, InsightsTimelineActions } from "./types";
import {
  InsightsTimelineContext,
  initialState,
  insightsTimelineReducer
} from "./context";
import { useInsightReport } from "../useInsightReport";

interface Props {
  children: ReactNode;
}

const InsightsTimelineProvider: FC<Props> = ({ children }) => {
  const enquiryId = useEnquiryId();
  const { search } = useLocation();
  const { state: reportState } = useInsightReport();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const [state, reducerDispatch] = useReducer(
    insightsTimelineReducer,
    initialState
  );

  const dispatch = useCallback((action: InsightsTimelineAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (
      state.status === InsightsTimelineStatus.idle &&
      !state.timeline?.length &&
      reportState.report !== undefined
    ) {
      dispatch({ type: InsightsTimelineActions.fetchTimeline });
    }

    if (
      state.status === InsightsTimelineStatus.deletingEvent &&
      state.eventId &&
      state.timeline?.length
    ) {
      const api = new InsightReportsApi();

      api
        .removeReportTimelineEvent({
          reportId: enquiryId,
          eventId: state.eventId
        })
        .then(response => {
          if (response.status) {
            if (!state.timeline || !state.eventId) return;

            dispatch({
              type: InsightsTimelineActions.deleteTimelineEventSuccess
            });

            setTimeout(() => {
              if (!state.timeline || !state.eventId) return;

              dispatch({
                type: InsightsTimelineActions.updateTimeline,
                timeline: state.timeline.filter(
                  (entry: TimelineEvent) => entry.id !== state.eventId
                )
              });
            }, 1600);
          } else {
            dispatch({
              type: InsightsTimelineActions.errorDeletingTimelineEvent,
              errorMessage: response.message
            });
          }
        })
        .catch(e => {
          dispatch({
            type: InsightsTimelineActions.errorDeletingTimelineEvent,
            errorMessage: e
          });
        });
    }
    if (
      state.status === InsightsTimelineStatus.fetching &&
      !state.timeline?.length
    ) {
      const api = new InsightReportsApi();

      let apiCall = () => api.getTimeline({ id: enquiryId });

      if (params.has("token")) {
        apiCall = () =>
          api.getTimelineWithShareToken({
            id: enquiryId,
            shareToken: params.get("token")!
          });
      }

      apiCall()
        .then(({ response: timeline, message }) => {
          if (timeline) {
            dispatch({
              type: InsightsTimelineActions.updateTimeline,
              timeline
            });
          } else {
            dispatch({
              type: InsightsTimelineActions.errorFetchingTimeline,
              errorMessage: message
            });
          }
        })
        .catch(e => {
          dispatch({
            type: InsightsTimelineActions.errorFetchingTimeline,
            errorMessage: e
          });
        });
    }

    if (
      state.status === InsightsTimelineStatus.error &&
      state.errorMessage === GetTimelineErrorCodes.IN_PROGRESS_ERROR
    ) {
      setTimeout(() => {
        dispatch({ type: InsightsTimelineActions.fetchTimeline });
      }, 10000);
    }
  }, [state, dispatch, enquiryId, params, reportState.report]);

  return (
    <InsightsTimelineContext.Provider value={providerValue}>
      {children}
    </InsightsTimelineContext.Provider>
  );
};

export default InsightsTimelineProvider;
