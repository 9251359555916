import React from "react";

import OrganisationOverview from "components/organisms/OrganisationOverview";

const ThemedOrganisationOverview = React.forwardRef(
  (props, organisationOverviewRef) => {
    return <OrganisationOverview {...props} ref={organisationOverviewRef} />;
  }
);

export default ThemedOrganisationOverview;
