import { Idam_Contracts_Enums_ReportRole } from "api/portal";

export enum ReportType {
  Person = "person",
  Organisation = "organisation"
}

export enum TagId {
  GroupMember = "groupMember",
  GroupRole = "groupRole",
  SharedGroup = "sharedGroup",
  SharingStatus = "sharingStatus",
  UserRole = "userRole"
}

export interface Tag {
  id: TagId;
  name: string;
}

export enum ReportStatus {
  ready = "ready",
  running = "running",
  updating = "updating",
  failed = "failed",
  deleted = "deleted"
}

export interface MonitoringPermissions {
  adverseMedia: boolean;
  sanctions: boolean;
}

export interface ReportPermissions {
  canDelete: boolean;
  canDeleteShareLink: boolean;
  canEdit: boolean;
  canEditSharingPreferences: boolean;
  canExport: boolean;
  canGenerateShareLink: boolean;
  canTransferOwnership: boolean;
  canView: boolean;
  canViewShareLink: boolean;
  canViewSharingPreferences: boolean;
  canViewAdverseMediaMonitoring: boolean;
  canEditAdverseMediaMonitoring: boolean;
  canViewSanctionMonitoring: boolean;
  canEditSanctionMonitoring: boolean;
}

export interface Report {
  id: string;
  status: ReportStatus;
  imageSrc: string;
  title: string;
  authorId: string;
  authorEmail: string;
  author: string;
  createdAt: string;
  context: string;
  projectReference?: string;
  tags: Tag[];
  risks: ScreeningRisk[];
  type: ReportType;
  permissions: ReportPermissions;
  monitoring: MonitoringPermissions;
  viewerModeUrl?: string;
}

export interface ReportsSearchResponse {
  totalCount: number;
  items: Report[];
}

export enum ReportSharePermission {
  Read = "Read",
  Write = "Write",
  Delete = "Delete",
  Transfer = "Transfer"
}

export interface SharedWithUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  permission: ReportSharePermission;
}

export interface SharedWithGroup {
  groupId: string;
  name: string;
  permission: ReportSharePermission;
}

export type SharedWithItem = SharedWithGroup | SharedWithUser;

export interface ReportSharePreferences {
  sharedWithUsers: SharedWithUser[];
  sharedWithGroups: SharedWithGroup[];
}

export interface ShareLinkToken {
  token: string;
}

export interface Requester {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ReportMeta {
  reportId: string;
  imageUrl: string;
  subjectItem: string;
  contextItems: string[];
}

export interface RequestAccess {
  requester: Requester;
  report: ReportMeta;
  permission: Idam_Contracts_Enums_ReportRole;
}

export enum ReportCollectionId {
  Recent = "recent",
  Unread = "unread"
}

export enum ScreeningRisk {
  Sanctions = "sanctions",
  Watchlists = "watchlists",
  Peps = "peps",
  Other = "other",
  RCA = "rca",
  SpecialInterest = "specialInterest"
}
