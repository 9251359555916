import { useState } from "react";
import { useLocation } from "react-router-dom";

import Api from "api/insight-reports";
import { Segment } from "api/insight-reports/types";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useSubjectName } from "util/hooks/useSubjectName";
import { InsightsAmaAnswer } from "../useInsightsAma/types";

export const useInsightsSources = ({
  answer
}: {
  answer: InsightsAmaAnswer;
}) => {
  const [error, setError] = useState<string>();
  const [sourcedSegments, setSourcedSegments] = useState<Segment[]>([]);
  const [loading, setLoading] = useState(false);
  const enquiryId = useEnquiryId();
  const subjectName = useSubjectName();
  const location = useLocation();

  const InsightsApi = new Api();

  const fetchSources = async () => {
    if (enquiryId && subjectName) {
      setLoading(true);
      setError(undefined);

      const params = new URLSearchParams(location.search);

      const { response, status, message } = await InsightsApi.getSources({
        reportId: enquiryId,
        subjectName,
        sectionId: "00000000-0000-0000-0000-000000000000",
        subsectionId: answer.id,
        shareToken: params.get("token")
      });

      if (!status) {
        setError(message);
        setSourcedSegments([]);
        return;
      }

      const segments = response!.elements[0].segments ?? [];
      const convertedSegments = segments.map(segment => ({
        text: segment.text,
        sources: segment.sources.map(source => ({
          wamSourceIds: source.wam_source_ids,
          extract: {
            text: source.extract.text,
            preContext: source.extract.pre_context,
            postContext: source.extract.post_context
          },
          supportLevel: source.support_level,
          supportStrength: source.support_strength,
          contradictionStrength: source.contradiction_strength
        })),
        supportLevel: segment.support_level,
        supportStrength: segment.support_strength,
        contradictionStrength: segment.contradiction_strength
      }));

      setSourcedSegments(convertedSegments);
      setLoading(false);
    } else {
      console.error("Source fetching not properly configured");
      setSourcedSegments([]);
    }
  };

  return {
    sourcedSegments,
    fetchSources,
    error,
    loading
  };
};
