import React, { FC, ReactNode, useState, ChangeEvent } from "react";

import { useSubjectName } from "util/hooks/useSubjectName";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import InsightReportsApi from "api/insight-reports";
import ModalWithContext from "components/organisms/InsightReport/ModalWithContext";
import { ModalVariant } from "components/organisms/InsightReport/ModalWithContext/types";
import { purple } from "styles/colors";

import S from "./styles";

interface ContextItem {
  key: string;
  value?: ReactNode;
}

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  context?: ContextItem[];
}

enum SubmissionStatus {
  idle = "idle",
  submitting = "submitting",
  submitted = "submitted"
}

const ProvideFeedbackModal: FC<Props> = ({ isOpen, onToggle, context }) => {
  const enquiryId = useEnquiryId();
  const subjectName = useSubjectName();
  const [feedbackText, setFeedbackText] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState(
    SubmissionStatus.idle
  );

  const onSubmitFeedback = () => {
    if (!subjectName || !feedbackText) {
      return;
    }

    setSubmissionStatus(SubmissionStatus.submitting);

    const api = new InsightReportsApi();
    const sectionContext = context?.find(({ key }) => key === "Section");
    const subsectionContext = context?.find(({ key }) => key === "Sub-section");

    api
      .provideFeedback({
        id: enquiryId,
        feedbackText,
        subjectName,
        section: sectionContext?.value as string | undefined,
        subsection: subsectionContext?.value as string | undefined
      })
      .then(response => {
        if (response.status) {
          setSubmissionStatus(SubmissionStatus.submitted);

          setTimeout(() => {
            setSubmissionStatus(SubmissionStatus.idle);
            setFeedbackText("");
            onToggle();
          }, 2000);
          return;
        }
        setSubmissionStatus(SubmissionStatus.idle);
      })
      .catch(e => {
        console.error(e);
        setSubmissionStatus(SubmissionStatus.idle);
      });
  };

  const onFeedbackChange = (
    event: ChangeEvent & { target: HTMLTextAreaElement }
  ) => {
    const { target } = event;

    if (target) {
      setFeedbackText(target.value);
    }
  };

  const onCloseModal = () => {
    setSubmissionStatus(SubmissionStatus.idle);
    setFeedbackText("");
    onToggle();
  };

  return (
    <ModalWithContext
      title="Insights Feedback"
      successText="Feedback submitted"
      successIcon={<S.TickIcon />}
      successIconColor={purple.darker}
      submitButtonText="Submit"
      submittingButtonText="Submitting"
      description="To help improve Insights, please share your feedback on this information"
      isSubmitting={submissionStatus === SubmissionStatus.submitting}
      isSubmitted={submissionStatus === SubmissionStatus.submitted}
      isOpen={isOpen}
      onSubmit={onSubmitFeedback}
      onCloseModal={onCloseModal}
      variant={ModalVariant.Info}
      overrideStyles
    >
      <S.Textarea
        onChange={onFeedbackChange}
        placeholder="Start typing to leave feedback..."
        value={feedbackText}
      />
    </ModalWithContext>
  );
};

export default ProvideFeedbackModal;
